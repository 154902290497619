<template>
    <aside class="col-lg-4 d-block d-md-none d-lg-none">
        <!-- Sidebar-->
        <div class="cz-sidebar rounded-lg box-shadow-lg" id="shop-sidebar">
            <div class="cz-sidebar-header box-shadow-sm">
                <button class="close ml-auto" type="button" @click="closeSidenavbar" data-dismiss="sidebar" aria-label="Close"><span class="d-inline-block font-size-xs font-weight-normal align-middle">Close sidebar</span><span class="d-inline-block align-middle ml-2" aria-hidden="true">&times;</span></button>
            </div>
            <div class="cz-sidebar-body">
                <Category />
                <!-- Price range-->
                <div class="widget mb-4 pb-4 border-bottom">
                    <h3 class="widget-title">Price</h3>
                    <div class="cz-range-slider" >
                        <div class="d-flex pb-1">
                            <div class="w-40 pr-2 mr-0">
                                <label for="">Min</label>
                                <div class="input-group input-group-sm">
                                    <input class="form-control cz-range-slider-value-min"  type="number" v-model="minPrice">
                                </div>
                            </div>
                            <div class="w-40 pl-1">
                            <label for="">Max</label>
                            <div class="input-group input-group-sm">
                                <input class="form-control cz-range-slider-value-max" type="number" v-model="maxPrice">
                            </div>
                        </div>
                        <div class="w-20 pl-1 mt-1">
                            <label for=""></label>
                            <div class="input-group input-group-sm">
                            <button @click="filterPrice" class="btn btn-primary btn-sm">></button>
                            </div>
                        </div>
                    
                    </div>
                </div>
            </div>
              
        </div>
          </div>
    </aside> 
</template>
<script>
import Category from '@/components/Category.vue'

export default {
    name: 'FilterSidebar',

    components: {
        Category,
    },
    data() {
        return {


        }
    }, 

    methods: {

        isInclude(products_id) {
            return this.anotherArrayName.includes(products_id)
        },

        closeSidenavbar() {
            document.querySelector(".cz-sidebar").classList.remove("show");
            document.querySelector(".toolbar-enabled").classList.remove("offcanvas-open");
        },

        filterPrice(){
            if(this.$route.query.page){
                this.axios.get('/products?price_from='+this.minPrice +'&price_to='+this.maxPrice+'&page=1')
                .then((res) => {

                this.products = res.data.data.data
                this.currentPage = res.data.data.current_page
                this.page = res.data.data.current_page
                this.lastPage = res.data.data.last_page
                this.totalProduct = res.data.data.total
                this.next_page_url = res.data.data.next_page_url
                this.prev_page_url = res.data.data.prev_page_url
                this.to = res.data.data.to

                this.$router.push({path: this.$route.fullPath, query: {pmin: this.minPrice, pmax: this.maxPrice, page: '1'} });
                
                })
            }else{
    
                this.axios.get('/products?price_from='+this.minPrice +'&price_to='+this.maxPrice+'&page='+this.currentPage)
                .then((res) => {

                        this.products = res.data.data.data
                        this.currentPage = res.data.data.current_page
                        this.page = res.data.data.current_page
                        this.lastPage = res.data.data.last_page
                        this.totalProduct = res.data.data.total
                        this.next_page_url = res.data.data.next_page_url
                        this.prev_page_url = res.data.data.prev_page_url
                        this.to = res.data.data.to

                        this.$router.push({path: this.$route.fullPath, query: {pmin: this.minPrice, pmax: this.maxPrice} });
                    

                })
            }
        },
    }
    
}
</script>