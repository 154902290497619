<template>
    <!-- Page Content-->
    <div class="container py-4 py-lg-5 my-4">
      <div class="row">
        <div class="col-md-6">
          <div class="card border-0 box-shadow">
            <div class="card-body">
              <h2 class="h4 mb-1">Sign in</h2>
              <!-- <div class="py-3"> -->
                <!-- <h3 class="d-inline-block align-middle font-size-base font-weight-semibold mb-2 mr-2">With social account:</h3>
                <div class="d-inline-block align-middle"> -->
                  <!-- <a class="social-btn sb-google mr-2 mb-2" href="#" data-toggle="tooltip" title="Sign in with Google"><i class="czi-google"></i></a> -->
                  <!-- <a class="social-btn sb-facebook mr-2 mb-2" href="https://www.instagram.com/batumuliashop/" data-toggle="tooltip" title="Sign in with Facebook"><i class="czi-instagram"></i></a> -->
                  <!-- <a class="social-btn sb-twitter mr-2 mb-2" href="#" data-toggle="tooltip" title="Sign in with Twitter"><i class="czi-twitter"></i></a> -->
                  <!-- </div> -->
              <!-- </div> -->
              <hr>
              <!-- <h3 class="font-size-base pt-4 pb-2">Or using form below</h3> -->

           <Login />

             
            </div>
          </div>
        </div>
        <div class="col-md-6 pt-4 mt-3 mt-md-0">
          <h2 class="h4 mb-3">No account? Sign up</h2>
          <p class="font-size-sm text-muted mb-4">Registration takes less than a minute but gives you full control over your orders.</p>
           <router-link to="/account/register">
         <button class="btn btn-primary"  >Register Now</button>
           </router-link>
        </div>
      </div>
    </div>
   
</template>
<script>
import Login from '@/components/Login.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AccountSignIn',
  components: {
    Login,


  },
 
   methods: { 
      ...mapActions({
      // setDialogStatus     : 'dialog/setStatus',
      // setDialogComponent  : 'dialog/setComponent',
      // setAuth             : 'auth/set',
      // setAlert            : 'alert/set',
            setDialogStatus     : 'dialog/setStatus',
      setDialogComponent  : 'dialog/setComponent',
      setAuth             : 'auth/set',
      setAlert            : 'alert/set',
      setWishlists        : 'wishlist/setWishlists',
      }),
     
     


    },
    
   computed: {
    ...mapGetters({
      // countCart : 'cart/count',
      // guest : 'auth/guest',
      // user : 'auth/user',
      // dialogStatus  : 'dialog/status',
      // currentComponent: 'dialog/component',
      // prevUrl : 'prevUrl', 
                    countCart : 'cart/count',
     wishlists  : 'wishlist/wishlists',
      guest : 'auth/guest',
      user : 'auth/user',
      dialogStatus  : 'dialog/status',
      currentComponent: 'dialog/component',
      prevUrl : 'prevUrl', 
    }),

   },

    dialog:{
      get () {
        return this.dialogStatus
      },
      set (value) {
        this.setDialogStatus(value)
      }
    }


    

}
</script>

