<template>
    <div class="payment">
        <!-- Page Title-->
        <div class="page-title-overlap bg-dark pt-4">
        <div class="container d-lg-flex justify-content-between py-2 py-lg-3">
            <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
            <nav aria-label="breadcrumb">
               <ol class="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                <li class="breadcrumb-item"><a class="text-nowrap" href="index.html"><i class="czi-home"></i>Home</a></li>
                <li class="breadcrumb-item text-nowrap"><a href="shop-grid-ls.html">Shop</a>
                </li>
                <li class="breadcrumb-item text-nowrap active" aria-current="page">Checkout</li>
                </ol>
            </nav>
            </div>
            <div class="order-lg-1 pr-lg-4 text-center text-lg-left">
            <h1 class="h3 text-light mb-0">Payment Information</h1>
            </div>
        </div>
        </div>
     <!-- Page Content-->
    <div class="container pb-5 mb-2 mb-md-4">
      <div class="row">
        <section class="col-lg-8">
          <!-- Steps-->
          <!-- <div class="steps steps-light pt-2 pb-3 mb-5"><a class="step-item active" href="shop-cart.html">
              <div class="step-progress"><span class="step-count">1</span></div>
              <div class="step-label"><i class="czi-cart"></i>Cart</div></a><a class="step-item active" href="checkout-details.html">
              <div class="step-progress"><span class="step-count">2</span></div>
              <div class="step-label"><i class="czi-user-circle"></i>Your details</div></a><a class="step-item active" href="checkout-shipping.html">
              <div class="step-progress"><span class="step-count">3</span></div>
              <div class="step-label"><i class="czi-package"></i>Shipping</div></a><a class="step-item active current" href="checkout-payment.html">
              <div class="step-progress"><span class="step-count">4</span></div>
              <div class="step-label"><i class="czi-card"></i>Payment</div></a><a class="step-item" href="checkout-review.html">
              <div class="step-progress"><span class="step-count">5</span></div>
              <div class="step-label"><i class="czi-check-circle"></i>Review</div></a></div> -->
        </section>  
          
          <!-- Payment methods accordion-->
          
          
          <!-- <v-container v-if="payment"> -->
              <div class="container mt-5 ">
                    <div class="row d-flex justify-content-center">
                        <div class="col-md-4 border" style="background:#FFF;">



 <div class="row mt-5">
                      <div class="col-md-12">
                          <!-- <h2 class="h6 pb-3 mb-2 mt-5">Metode Pembayaran</h2> -->
          <h1>Transfer Bank BCA</h1>

          <p>Gunakan ATM/iBanking/mBanking/setor tunai untuk melakukan pembayaran transfer</p>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-md-12">
                            <h5>Transfer ke nomer rekening:</h5>
                            <div class="row">
                                <div class="col-4 d-flex align-items-center"><img src="/img/bca.png" width="100px"></div>
                                <div class="col-8"><h3><b>22-111-85-793</b></h3></div>
                            </div>
                             
                            <p><small class="font-size-sm">KCP Menara Imperium</small><br><b class="font-size-lg">a/n. Naveed Zafar</b></p>
                            <p></p> 

                            <hr class="mt-5 ">
                            <button type="button" class="btn btn-link btn-block w-100 mb-5" v-clipboard:copy="rekening" v-clipboard:success="onCopy" v-clipboard:error="onError"
                            ><h5 class="text-center" style="color:blue"><u>SALIN NO.REK</u></h5></button>
                            <!-- <button type="button" class="btn btn-link btn-block w-100 mb-5" v-on:click="copyCode" :value="2211185793"><h5 class="text-center" style="color:blue"><u>SALIN NO.REK</u></h5></button> -->
                            <table>
                                <tr><th>Order ID</th><td>: {{ payment.order_id }}</td></tr>
                                <tr><th>Invoice Number</th><td> : {{ payment.invoice_number }}</td></tr>
                                <tr><th>Total Bill</th><td>: Rp. {{payment.total_bill.toLocaleString('id-ID') }}</td></tr>
                            </table>
                            <hr class="my-5">
                      </div>
                  </div>
                  
                  
            <div class="container  mx-0 text-center">
                <h5>Jumlah yang harus dibayarkan:</h5>
                <h1>Rp. {{payment.total_bill.toLocaleString('id-ID') }}</h1>
                 <button type="button" class="btn btn-link btn-block w-100 mb-4" 
                 v-clipboard:copy="jumlahbill"
                v-clipboard:success="onCopytotal"
                v-clipboard:error="onErrortotal"
                 ><h5 class="text-center" style="color:blue"><u>SALIN JUMLAH</u></h5></button>
                 <p><strong>Silahkan lakukan pembayaran ke no. rekening diatas sebelum 1x24 jam </strong></p>
                 <!-- <button type="button" class="btn btn-link btn-block w-100 mb-4" v-on:click="copyCode" value="2211185793"><h5 class="text-center" style="color:blue"><u>SALIN JUMLAH</u></h5></button> -->
                <button class="btn btn-success mb-5 px-5 btn-block w-100" color="success" @click="finish">Finish</button>
            </div>
              </div> 




                        </div>
                    </div>
                 
           



    </div>
    </div>

    </div>
</template>
<script>
 import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Payment',
  components: {
    

  },
   data() {
       return {
            jumlahbill: 0,
           rekening:'2211185793',
           
       }
    },
  computed: {
      ...mapGetters({
            payment  : 'payment',
        }),
  },
  created(){
      this.jumlahbill = this.payment.total_bill

    if(this.payment==undefined){
        this.setAlert({
            status : true,
            text  : 'Payment undefined',
            color  : 'warning',
        })
        this.$router.push('/')
    }
}, 
methods: {
    ...mapActions({
        setAlert: 'alert/set',
    }),
    finish(){

            this.setAlert({
                status : true,
                text  : 'Transaction done',
                color  : 'success',
            })
            this.$router.push('/')
        },
     copyCode: async function (e) {
            this.code = e.target.value;
            
            const copyText = await this.$refs.myInput
            copyText.select()
            document.execCommand("copy")
            //this.cStype = 'hidden';
        },
        onCopy: function (e) {
                alert('Berhasil Salin Nomer Rekening : ' + e.text)
            },
            onError: function (e) {
                alert('Gagal Salin Nomer Rekening')
                console.log(e);
            },
        onCopytotal: function (e) {
            
                alert('Berhasil Salin Jumlah Transfer : ' + e.text)

            },
            onErrortotal: function (e) {
                alert('Gagal Salin Jumlah Transfer')
                console.log(e);
            }
    
},

}
</script>