export default {
    namespaced: true,
    state: {
      wishlists : [],
    },
    mutations: {
      setWishlists: (state, value) => {
          state.wishlists = value
      },
      // update: (state, payload) => {
      //   // mendeteksi payload ada di index keberapa?
      //   let idx = state.wishlists.indexOf(payload);

      //   state.wishlists.splice(idx,1);
      //   const parsed = JSON.stringify(state.wishlists);
      //   localStorage.setItem('wishlists', parsed); 
        
      // },

  },
  actions: {
      setWishlists: ({commit}, value) => {
          commit('setWishlists', value)
      },
      remove: ({state}, payload) => {
        let wishlistItem = state.wishlists.findIndex(item => item.id === payload.id)
        state.wishlists.splice(wishlistItem,1);
        // if(wishlistItem){
        //   commit('update', wishlistItem)
        // }
      }, 

  },
  getters: {
      wishlists: state => state.wishlists,
  }
  }