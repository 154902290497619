<template>
    <button class="btn btn-primary btn-sm" v-if="!guest" @click="logout"><i class="czi-sign-out mr-2"></i>Sign out</button>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    name: 'LogoutDesktop',
      methods: { 
      ...mapActions({
        setDialogStatus   : 'dialog/setStatus',
        setDialogComponent: 'dialog/setComponent',
        setAuth           : 'auth/set',
        setWishlists      : 'wishlist/setWishlists',
        setAlert          : 'alert/set',
        setProvinces      : 'region/setProvinces',
        setCities         : 'region/setCities',
      }),
      logout(){
        let config = {
          headers: {
            'Authorization': 'Bearer ' + this.user.api_token,
          },
        }
        this.axios.post('/logout', {}, config)
       
          .then(() => {
            this.setAuth({}) // kosongkan auth ketika logout
            this.setWishlists([]) 
            this.setAlert({
                status : true,
                color  : 'success',
                text  : 'Logout successfully',
              })
            this.$router.push( { path: '/' })
          })
          .catch(() => {
            
            // let {data} = error.response   
            // this.setAlert({
            //     status : true,
            //     color  : 'danger',
            //     text  :'Logout failed',
            // })
             this.setAuth({}) // kosongkan auth ketika logout
              this.setWishlists([]) 
             this.$router.push( { path: '/account/sign-in' })
          })
      },
     


    },
       computed: {
    ...mapGetters({
      provinces     : 'region/provinces',
      cities        : 'region/cities',
      countCart     : 'cart/count',
      guest         : 'auth/guest',
      user          : 'auth/user',
      wishlists  : 'wishlist/wishlists',
      dialogStatus  : 'dialog/status',
      currentComponent: 'dialog/component',
    }),

   },
}
</script>

<style scoped>

</style>