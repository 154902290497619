<template>
<div class="shopdetail">
<Breadcrumb />
 <!-- Page Content-->

     

    <div class="container">
      <!-- Gallery + details-->
      <div class="bg-light box-shadow-lg rounded-lg px-4 py-3 mb-5 product-shop spad page-details">
        <div class="px-lg-3">
          <div class="row">
            

            <!-- Product gallery-->
            <div class="col-lg-7 pr-lg-0 pt-lg-4" >

               <AddToWishlistDetailGbr :productDetails="productDetails" />

                  <div class="product-pic-zoom mb-2 d-none d-sm-block">
                    <img class="product-big-img w-100" :src="base_url + '/storage/images/products/' + gambar_default" alt="Product image">
                  </div>
                
                <div class="product-thumbs d-none d-sm-block" v-if="productDetails.galleries.length >0">
                  <carousel :dots="false" :nav="false" items="6" class="d-flex justify-content-left" style="height:100px; width:100%" >
                    <template slot="prev"><span class="prev"><i class="czi-arrow-left font-size-sm"></i></span></template>
                    
                      <div v-for="ss in productDetails.galleries" :key="ss.id" class="mr-2 " @click="changeImage(ss.photo)" :class="ss.photo == gambar_default ? 'active' : '' " style="width:100px; height:100px;background-position: center; background-size: cover;" :style="'background-image:url('+ base_url +'/storage/images/products/'+ ss.photo +');'" >
                      </div> 

                      <template slot="next"><span class="next"><i class="czi-arrow-right font-size-sm"></i></span></template>

                    


                  </carousel>
                </div>


                <div class="d-block d-sm-none mb-2" v-if="productDetails.galleries.length >0">
                  <carousel :dots="true" :nav="false" items="1" class="d-flex justify-content-left" >
                    <div v-for="ss in productDetails.galleries" :key="ss.id">
                    <img class="product-big-img w-100"  :src="base_url + '/storage/images/products/' + ss.photo" alt="Product image">
                    </div>
                  </carousel>
                </div>

                          <!-- <div class="embed-responsive embed-responsive-16by9 mt-5">
  <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/ISNDycpmJbI" allowfullscreen></iframe>
</div> -->
<div v-if="productDetails.link_vid" class="embed-responsive embed-responsive-16by9 mt-5">
  <iframe class="embed-responsive-item" :src="productDetails.link_vid" allowfullscreen></iframe>
</div>

            <!-- Product-->
              <!-- <carousel  class="product-slider" :items="1" :nav="true"  :navText="unit_nav_text1" :navClass="unit_nav_class" >
              <div class="product-item" v-for="ss in productDetails.galleries" v-bind:key="ss.id" >
                <img :src="ss.photo" class="product-big-img w-100" alt="Product" >
              </div>
            </carousel> -->
          <div class="mt-5 d-none d-sm-block" style="border-top:1px solid #ccc">
            <div class="row my-3">
              <div class="col-md-12 mt-3">
                <p class="font-size-sm"><i>"Every gem we sell are all precious to us, we source all of our gemstones straight from the mines, to get you the best price and quality. Support local miners, who mined your gem that you purchase from us. Thank you for being apart of this process."</i></p>
                <p><small>Best wishes, <strong>Naveed Zafar</strong></small></p>
              </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h4 style="color:#78367f;"><strong>Promo</strong></h4>
                </div>
            </div>
            <div class="row" v-if="productDetails.discount_status != 1 && productDetails.free_ongkir != 1" >
                <div class="col-12 font-size-xs" style="color:#ccc;">
                     <i>Not available yet</i>
                </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div v-if="productDetails.discount_status == 1 && productDetails.discount_persen > 0" class="shadow mb-2" style="border:1px dotted #ccc; width:180px; 
                ">
                    <div class="p-2" style="border-right:25px solid #78367f;">
                        <small>Discount <i class="fa fa-percent" style="color:#666;"></i></small><br>
                        <h4 class="my-0"><strong>{{ productDetails.discount_persen }}%</strong></h4>
                        <small style="font-size:10px;">Save {{ (productDetails.price-productDetails.discount_price).toLocaleString('id-ID') }}</small>
                    </div>
                  
                </div>
              </div>

               <div class="col-md-4"> 
                <div v-if="productDetails.free_ongkir == 1" class="shadow mb-2" style="border:1px dotted #ccc; width:180px;">
                   <div class="p-2" style="border-right:25px solid #78367f;">
                      <small>Shipping <i class="fa fa-paper-plane-o" aria-hidden="true"></i></small><br>
                      <h4 class="my-0"><strong>Free Shipping</strong></h4>
                      <small style="font-size:10px;">Nation Wide</small>
                     </div>
                </div>
              </div>

 

              <div class="col-md-4 mt-4 mt-md-0" style="border-left: 1px solid #ccc">

                    <div class=" mb-3" v-if="productDetails.recommendations == 1"><i class="czi-thumb-up font-size-lg text-accent mr-1" style="color:#78367f;"></i> <strong>Recommended</strong></div>
                    <div class=" mb-3"  v-if="productDetails.langka == 1"><i class="czi-sun font-size-lg text-accent mr-1"></i> <strong>Rare Find.</strong> <small>There's only {{ productDetails.stock }} of of these in stock.</small></div>
                    <div class=" mb-3"  v-if="productDetails.discount_status == 1"><i class="czi-lable font-size-lg text-accent mr-1"></i> <strong>Discount item</strong></div>

              </div>
            </div>
          </div>

        
          


          <div class="mt-4 mb-4 d-none d-sm-block" style="border-top:1px solid #ccc">
            <div class="row mt-3">
              <div class="col-md-4">
                <img class="img-fluid w-100" src="../../assets/shopdetail/jewelry.png" alt="">
              </div>
              <div class="col-md-8">
                  <h4 style="color:#78367f;"><strong>Want to make your dream jewelry come true? Customize this gem with us today!</strong></h4>
                  <p>Not sure where to start?</p>
                  <div class="row">
                    <div class="col-md-6">
                        <a :href="'https://wa.me/628176781986?text=Halo, Saya tertarik membuat custom jewelry dengan batu di dalam link ini: ' + urlwindow  + this.$route.path"><button class="btn btn-primary btn-md btn-block mb-2"><i class="fa fa-whatsapp"></i> Whatsapp Now</button></a>
                    </div>
                  </div>
              </div>
            </div>
              
          </div>
          
          


            </div>

           

            <!-- Product details-->
            <div class="col-lg-5 pt-4 pt-lg-0" >
              <div class="product-details ml-auto pb-3 pt-4">
                
                                             <!-- Go to www.addthis.com/dashboard to customize your tools -->
                <div class="addthis_inline_share_toolbox"></div>
                <div class="d-flex justify-content-between align-items-center mb-2" >
                  
                  
                    <span class="d-inline-block font-size-sm text-body align-middle mt-3 ml-1" >CODE: {{ productDetails.kode}}<br>
                      {{ productDetails.type }}</span>

                  <AddToWishlistDetail :productDetails="productDetails" />
                 <!-- <span class="mt-3"> <button class="btn-wishlist btn-sm" type="button" data-toggle="tooltip" data-placement="left" title="Add to wishlist" @click="savewishlist(productDetails.id)">
                    <i class="fa fa-heart " :class="isInclude(productDetails.id)?'wishlist-active': ''" aria-hidden="true" ></i> 
                    </button> <strong><small>Wishlist</small></strong></span> -->
            

                </div>
                <h2 style="color:#78367f;"><b>{{ productDetails.name }}</b></h2>
                <div class="mb-3">
                  <!-- <del class="text-muted font-size-lg mr-3">Rp {{ productDetails.price }}</del><br> -->
                  

                     <div v-if="productDetails.discount_status == 1" class="font-size-lg text-accent pt-2" > 
                            <span class="h3 font-weight-bold  mr-1" style="font-size:18px; color:grey;" v-if="productDetails.discount_price>0">
                          Rp {{ productDetails.discount_price.toLocaleString('id-ID') }}
                          </span><br>
                          
                            <span class="font-size-xs p-1 mr-2" style="background-color:red; color:#fff; border-radius:3px;"><strong>{{ productDetails.discount_persen }}%</strong></span> 
                            <span class="font-size-sm" style="text-decoration: line-through;color:gray;">Rp {{ productDetails.price.toLocaleString('id-ID') }}</span>
                        </div>
                        <div v-else class="">
                         <span class="h3 font-weight-bold  mr-1" style="font-size:18px; color:grey;" v-if="productDetails.price>0">
                          Rp {{ productDetails.price.toLocaleString('id-ID') }}
                          </span>
                        </div> 
                  <!-- <span class="badge badge-danger badge-shadow align-middle mt-n2">Sale</span> -->
                </div>

                <div> <p v-html="productDetails.about"> 
                 </p>
                </div>

                
                

                <!-- Product panels-->
                <!-- <div class="accordion mb-4" id="productPanels">
                  <div class="card"> -->
                    <!-- <div class="card-header">
                      <h3 class="accordion-heading"><a href="#productInfo" role="button" data-toggle="collapse" aria-expanded="true" aria-controls="productInfo"><i class="czi-announcement text-muted font-size-lg align-middle mt-n1 mr-2"></i>Stone Information And Specification<span class="accordion-indicator"></span></a></h3>
                    </div> -->

                    <!-- <div class="collapse show" id="productInfo" data-parent="#productPanels">
                      <div class="card-body"> -->
                        <!-- <h6 class="font-size-sm mb-2">Description</h6> -->

          

                        <table class="table table-hover" style="font-size:15px;">
                          <tbody>
                            <tr v-if="productDetails.categories">
                              <td class="d-flex justify-content-between"><span>Category</span> <span>:</span></td>
                              <td> <a href="">{{ productDetails.categories.name }}</a></td>
                            </tr>
                            <tr v-if="productDetails.subcategories">
                             <td class="d-flex justify-content-between"><span>Type</span> <span>:</span></td>
                              <td> <a href="">{{ productDetails.subcategories.name }}</a></td>
                            </tr>
                            <tr v-if="productDetails.ringsizes_id">
                             <td class="d-flex justify-content-between"><span>Ring Size</span> <span>:</span></td>
                              <td> {{ productDetails.ringsizes_id }}</td>
                            </tr>
                            <tr v-if="productDetails.pjg_rantai">
                             <td class="d-flex justify-content-between"><span>Panjang Rantai</span> <span>:</span></td>
                              <td> {{ productDetails.pjg_rantai }}</td>
                            </tr>
                            <tr v-if="productDetails.material">
                             <td class="d-flex justify-content-between"><span>Material</span> <span>:</span></td>
                              <td> {{ productDetails.material }}</td>
                            </tr>
                            <tr v-if="productDetails.carats">
                             <td class="d-flex justify-content-between"><span>Weight</span> <span>:</span></td>
                              <td> {{ productDetails.carats }}</td>
                            </tr>
                            <tr v-if="productDetails.dimensions">
                              <td class="d-flex justify-content-between"><span class="mr-3">Dimensions</span> <span>:</span></td>
                              <td> {{ productDetails.dimensions }}</td>
                            </tr>
                            <tr v-if=" productDetails.colors">
                              <td class="d-flex justify-content-between"><span>Color</span> <span>:</span></td>
                              <td> {{ productDetails.colors.name }}</td>
                            </tr>
                            <tr v-if="productDetails.shapes">
                              <td class="d-flex justify-content-between"><span>Shape</span> <span>:</span></td>
                              <td> {{ productDetails.shapes.name }}</td>
                            </tr>
                            <tr v-if="productDetails.cuttings">
                              <td class="d-flex justify-content-between"><span>Cut</span> <span>:</span></td>
                              <td> {{ productDetails.cuttings.name }}</td>
                            </tr>
                             <tr v-if="productDetails.clarities">
                                <td class="d-flex justify-content-between"><span>Clarity</span> <span>:</span></td>
                              <td> {{ productDetails.clarities.name }} </td>
                            </tr>
                            <tr v-if="productDetails.transparancies">
                               <td class="d-flex justify-content-between"><span>Transparancy</span> <span>:</span></td>
                              <td>{{ productDetails.transparancies.name }}</td>
                            </tr>

                             <tr v-if="productDetails.treatment">
                               <td class="d-flex justify-content-between"><span>Treatment</span> <span>:</span></td>
                              <td>{{ productDetails.treatment }}</td>
                            
                            </tr>
               
                            <tr v-if="productDetails.origins">
                              <td class="d-flex justify-content-between"><span>Est. Origin</span> <span>:</span></td>
                              <td>{{ productDetails.origins.name }}</td>
                            </tr> 
                            <tr v-if="productDetails.sertifikasi">
                              <td class="d-flex justify-content-between"><span>Certificate</span> <span>:</span></td>
                              <td>{{ productDetails.sertifikasi }}</td>
                            </tr>
                             <tr v-if="productDetails.description">
                              <td class="d-flex justify-content-between"><span>Note</span> <span>:</span></td>
                              <td><span style="float:left;"> &nbsp;</span>  <p style="float:left;" v-html="productDetails.description"></p></td>
                            </tr> 
                            <tr>
                              <td colspan="2">
                                <div class="mb-grid-gutter" v-if="productDetails.stock > 0">
                                <div class="form-group d-flex align-items-center">
                                  <select class="custom-select mr-3" style="width: 5rem;" hidden>
                                    <option value="1">1</option>
                                  </select>
                                  <!-- <router-link to="/cart"> -->
                                  <!-- <a @click="saveKeranjang(productDetails.id, productDetails.name, productDetails.price, productDetails.galleries[0].photo)" class="btn btn-primary btn-shadow btn-block" type="submit"><i class="czi-cart font-size-lg mr-2"></i>Add to Cart</a> -->
                                   <button @click="buy" :disabled="productDetails.stock<1" class="btn btn-primary btn-shadow btn-block"><i class="czi-cart font-size-lg mr-2"></i>Add to Cart</button>
                                  <!-- </router-link> -->
                                </div>
                              </div>

                              <div class="mb-grid-gutter" v-else>
                                <div class="form-group d-flex align-items-center">
                                  <button class="btn btn-secondary btn-sm btn-block mb-2" type="button" disabled>Sold Out</button>
                                </div>
                              </div>


                     
                                <div class="btn-group btn-group-sm d-flex justify-content-center" role="group" aria-label="Basic example" >
                          <a :href="'http://wa.me/628176781986?text=Halo, saya ingin tau lebih detail produk:%0A%0A' + urlwindow  + this.$route.path" target="_blank" role="button"  class="btn " style="background-color: #E9EDF6; color:#78367f"><i class="fa fa-whatsapp"></i> Whatsapp</a>
                           <a href="tel:+62218354050" role="button" class="btn border-left " style="background-color: #E9EDF6; color:#78367f;"><i class="fa fa-phone"></i> Call Us</a>
                          <a href="mailto:contact@batumuliashop.com" role="button" class="btn border-left" style="background-color: #E9EDF6; color:#78367f"><i class="fa fa-envelope-o"></i> Email</a>
                        </div>
     
            
                              </td>
                            </tr>
                          </tbody>
                        </table>
    


                      
                 
                    <!-- </div> -->
                  <!-- </div> -->

                  <!-- <div class="card"> -->
                    <!-- <div class="card-header">
                      <h3 class="accordion-heading"><a class="collapsed" href="#shippingOptions" role="button" data-toggle="collapse" aria-expanded="true" aria-controls="shippingOptions"><i class="czi-delivery text-muted lead align-middle mt-n1 mr-2"></i>Shipping options<span class="accordion-indicator"></span></a></h3>
                    </div> -->
                    <!-- <div class="collapse" id="shippingOptions" data-parent="#productPanels">
                      <div class="card-body font-size-sm">
                        <div class="d-flex justify-content-between border-bottom pb-2">
                          <div>
                            <div class="font-weight-semibold text-dark">Courier</div>
                            <div class="font-size-sm text-muted">2 - 4 days</div>
                          </div>
                          <div>$26.50</div>
                        </div>
                        <div class="d-flex justify-content-between border-bottom py-2">
                          <div>
                            <div class="font-weight-semibold text-dark">Local shipping</div>
                            <div class="font-size-sm text-muted">up to one week</div>
                          </div>
                          <div>$10.00</div>
                        </div>
                        <div class="d-flex justify-content-between border-bottom py-2">
                          <div>
                            <div class="font-weight-semibold text-dark">Flat rate</div>
                            <div class="font-size-sm text-muted">5 - 7 days</div>
                          </div>
                          <div>$33.85</div>
                        </div>
                        <div class="d-flex justify-content-between border-bottom py-2">
                          <div>
                            <div class="font-weight-semibold text-dark">UPS ground shipping</div>
                            <div class="font-size-sm text-muted">4 - 6 days</div>
                          </div>
                          <div>$18.00</div>
                        </div>
                        <div class="d-flex justify-content-between pt-2">
                          <div>
                            <div class="font-weight-semibold text-dark">Local pickup from store</div>
                            <div class="font-size-sm text-muted">&mdash;</div>
                          </div>
                          <div>$0.00</div>
                        </div>
                      </div>
                    </div> -->
                  <!-- </div>
                </div> -->
                <!-- Sharing-->
                <!-- <h6 class="d-inline-block align-middle font-size-base my-2 mr-2">Follow:</h6><a class="share-btn sb-instagram mr-2 my-2" href="https://www.instagram.com/batumuliashop"><i class="czi-instagram"></i>Instagram</a><a class="share-btn sb-facebook my-2" href="https://id-id.facebook.com/ghulamgem/"><i class="czi-facebook"></i>Facebook</a> -->
              </div>
            </div>


 <div class="mt-5 d-block d-sm-none" style="border-top:1px solid #ccc">
            <div class="row my-3">
              <div class="col-md-12 mt-3">
                <p class="font-size-sm"><i>"Every gem we sell are all precious to us, we source all of our gemstones straight from the mines, to get you the best price and quality. Support local miners, who mined your gem that you purchase from us. Thank you for being apart of this process."</i></p>
                <p><small>Best wishes, <strong>Naveed Zafar</strong></small></p>
              </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h4 style="color:#78367f;"><strong>Promo</strong></h4>
                </div>
            </div>
            <div class="row" v-if="productDetails.free_ongkir != 1 && productDetails.discount_status != 1" >
                <div class="col-12 font-size-xs" style="color:#ccc;">
                     <i>Not available yet</i>
                </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div v-if="productDetails.discount_status == 1 && productDetails.discount_persen > 0" class="shadow mb-2" style="border:1px dotted #ccc; width:180px; 
                ">
                    <div class="p-2" style="border-right:25px solid #78367f;">
                        <small>Discount <i class="fa fa-percent" style="color:#666;"></i></small><br>
                        <h4 class="my-0"><strong>{{ productDetails.discount_persen }}%</strong></h4>
                        <small style="font-size:10px;">Save {{ (productDetails.price-productDetails.discount_price).toLocaleString('id-ID') }}</small>
                    </div>
                  
                </div>
              </div>

               <div class="col-md-4"> 
                <div v-if="productDetails.free_ongkir == 1" class="shadow mb-2" style="border:1px dotted #ccc; width:180px;">
                   <div class="p-2" style="border-right:25px solid #78367f;">
                      <small>Shipping <i class="fa fa-paper-plane-o" aria-hidden="true"></i></small><br>
                      <h4 class="my-0"><strong>Free Shipping</strong></h4>
                      <small style="font-size:10px;">Nation Wide</small>
                     </div>
                </div>
              </div>

               

              <div class="col-md-4 mt-4 mt-md-0" style="border-left: 1px solid #ccc">

                    <div class=" mb-3" v-if="productDetails.recommendations == 1"><i class="czi-thumb-up font-size-lg text-accent mr-1" style="color:#78367f;"></i> <strong>Recommended</strong></div>
                    <div class=" mb-3"  v-if="productDetails.langka == 1"><i class="czi-sun font-size-lg text-accent mr-1"></i> <strong>Rare Find.</strong> <small>There's only {{ productDetails.stock }} of of these in stock.</small></div>
                    <div class=" mb-3"  v-if="productDetails.discount_status == 1"><i class="czi-lable font-size-lg text-accent mr-1"></i> <strong>Discount item</strong></div>

              </div>
            </div>
          </div>

          <div class="mt-4 mb-4 d-block d-sm-none" style="border-top:1px solid #ccc">
            <div class="row mt-3">
              <div class="col-md-4">
                <img class="img-fluid w-100" src="../../assets/shopdetail/jewelry.png" alt="">
              </div>
              <div class="col-md-8">
                  <h4 style="color:#78367f;"><strong>Want to make your dream jewelry come true? Customize with us today!</strong></h4>
                  <p>Not sure where to start?</p>
                  <div class="row">
                    <div class="col-md-6">
                        <a :href="'https://wa.me/628176781986?text=Halo, Saya tertarik membuat custom jewelry dengan batu di dalam link ini: ' + urlwindow  + this.$route.path"><button class="btn btn-primary btn-md btn-block mb-2"><i class="fa fa-whatsapp"></i> Whatsapp Now</button></a>
                    </div>
                  </div>
              </div>
            </div>
              
          </div>




          </div>
        </div>
      </div>


      <!-- Product description section 2-->
      <div class="row align-items-center py-4 py-lg-5 px-5" v-if="productDetails.subcategories.description != null">
        <div class="col-lg-6 col-md-6" >
          <h1 class="" >{{ productDetails.subcategories.title }}</h1>
          <hr style="border-bottom:1px solid #666; width:50px; ">
          <p class="font-size-sm text-muted pb-md-2" v-html="productDetails.subcategories.description" ></p>
        </div>

        <div class="col-lg-6 col-md-6 " >
          <!-- <img class="d-block rounded-lg" :src="'http://bmsvuenew-api.test/' . productDetails.subcategories.photo" :alt="productDetails.subcategories.title"> -->
          <img class="d-block rounded-lg img-fluid" :src="base_url + '/storage/images/category/' + productDetails.subcategories.photo" :alt="productDetails.subcategories.title">

        </div>
        
      </div>
    </div>
    <!-- Reviews-->
    <!-- <div class="border-top border-bottom my-lg-3 py-5">
      <div class="container pt-md-2" id="reviews"> -->
        <!-- <div class="row pb-3">
          <div class="col-lg-4 col-md-5">
            <h2 class="h3 mb-4">74 Reviews</h2>
            <div class="star-rating mr-2"><i class="czi-star-filled font-size-sm text-accent mr-1"></i><i class="czi-star-filled font-size-sm text-accent mr-1"></i><i class="czi-star-filled font-size-sm text-accent mr-1"></i><i class="czi-star-filled font-size-sm text-accent mr-1"></i><i class="czi-star font-size-sm text-muted mr-1"></i></div><span class="d-inline-block align-middle">4.1 Overall rating</span>
            <p class="pt-3 font-size-sm text-muted">58 out of 74 (77%)<br>Customers recommended this product</p>
          </div>
          <div class="col-lg-8 col-md-7">
            <div class="d-flex align-items-center mb-2">
              <div class="text-nowrap mr-3"><span class="d-inline-block align-middle text-muted">5</span><i class="czi-star-filled font-size-xs ml-1"></i></div>
              <div class="w-100">
                <div class="progress" style="height: 4px;">
                  <div class="progress-bar bg-success" role="progressbar" style="width: 60%;" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div><span class="text-muted ml-3">43</span>
            </div>
            <div class="d-flex align-items-center mb-2">
              <div class="text-nowrap mr-3"><span class="d-inline-block align-middle text-muted">4</span><i class="czi-star-filled font-size-xs ml-1"></i></div>
              <div class="w-100">
                <div class="progress" style="height: 4px;">
                  <div class="progress-bar" role="progressbar" style="width: 27%; background-color: #a7e453;" aria-valuenow="27" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div><span class="text-muted ml-3">16</span>
            </div>
            <div class="d-flex align-items-center mb-2">
              <div class="text-nowrap mr-3"><span class="d-inline-block align-middle text-muted">3</span><i class="czi-star-filled font-size-xs ml-1"></i></div>
              <div class="w-100">
                <div class="progress" style="height: 4px;">
                  <div class="progress-bar" role="progressbar" style="width: 17%; background-color: #ffda75;" aria-valuenow="17" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div><span class="text-muted ml-3">9</span>
            </div>
            <div class="d-flex align-items-center mb-2">
              <div class="text-nowrap mr-3"><span class="d-inline-block align-middle text-muted">2</span><i class="czi-star-filled font-size-xs ml-1"></i></div>
              <div class="w-100">
                <div class="progress" style="height: 4px;">
                  <div class="progress-bar" role="progressbar" style="width: 9%; background-color: #fea569;" aria-valuenow="9" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div><span class="text-muted ml-3">4</span>
            </div>
            <div class="d-flex align-items-center">
              <div class="text-nowrap mr-3"><span class="d-inline-block align-middle text-muted">1</span><i class="czi-star-filled font-size-xs ml-1"></i></div>
              <div class="w-100">
                <div class="progress" style="height: 4px;">
                  <div class="progress-bar bg-danger" role="progressbar" style="width: 4%;" aria-valuenow="4" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div><span class="text-muted ml-3">2</span>
            </div>
          </div>
        </div>
        <hr class="mt-4 pb-4 mb-3"> -->
        <div class="row">
          <!-- Reviews list-->
          <div class="col-md-7">
            <!-- <div class="d-flex justify-content-end pb-4">
              <div class="form-inline flex-nowrap">
                <label class="text-muted text-nowrap mr-2 d-none d-sm-block" for="sort-reviews">Sort by:</label>
                <select class="custom-select custom-select-sm" id="sort-reviews">
                  <option>Newest</option>
                  <option>Oldest</option>
                  <option>Popular</option>
                  <option>High rating</option>
                  <option>Low rating</option>
                </select>
              </div>
            </div> -->
            <!-- Review-->
            <!-- <div class="product-review pb-4 mb-4 border-bottom">
              <div class="d-flex mb-3">
                <div class="media media-ie-fix align-items-center mr-4 pr-2"><img class="rounded-circle" width="50" src="img/shop/reviews/01.jpg" alt="Rafael Marquez"/>
                  <div class="media-body pl-3">
                    <h6 class="font-size-sm mb-0">Rafael Marquez</h6><span class="font-size-ms text-muted">June 28, 2019</span>
                  </div>
                </div>
                <div>
                  <div class="star-rating"><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star"></i>
                  </div>
                </div>
              </div>
              <p class="font-size-md mb-2">Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est...</p>
              <ul class="list-unstyled font-size-ms pt-1">
                <li class="mb-1"><span class="font-weight-medium">Purchased item:&nbsp;</span>1.5mm Simple Thin Plain Wedding Band, 14k Solid Gold Smooth</li>
              </ul>
            </div> -->
            <!-- Review-->
            <!-- <div class="product-review pb-4 mb-4 border-bottom">
              <div class="d-flex mb-3">
                <div class="media media-ie-fix align-items-center mr-4 pr-2"><img class="rounded-circle" width="50" src="img/shop/reviews/02.jpg" alt="Barbara Palson"/>
                  <div class="media-body pl-3">
                    <h6 class="font-size-sm mb-0">Barbara Palson</h6><span class="font-size-ms text-muted">May 17, 2019</span>
                  </div>
                </div>
                <div>
                  <div class="star-rating"><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i>
                  </div>
                </div>
              </div>
              <p class="font-size-md mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
              <ul class="list-unstyled font-size-ms pt-1">
                <li class="mb-1"><span class="font-weight-medium">Purchased item:&nbsp;</span>Consequuntur magni, voluptatem sequi, tempora</li>
              </ul>
            </div> -->
            <!-- Review-->
            <!-- <div class="product-review pb-4 mb-4 border-bottom">
              <div class="d-flex mb-3">
                <div class="media media-ie-fix align-items-center mr-4 pr-2"><img class="rounded-circle" width="50" src="img/shop/reviews/03.jpg" alt="Daniel Adams"/>
                  <div class="media-body pl-3">
                    <h6 class="font-size-sm mb-0">Daniel Adams</h6><span class="font-size-ms text-muted">May 8, 2019</span>
                  </div>
                </div>
                <div>
                  <div class="star-rating"><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star"></i><i class="sr-star czi-star"></i>
                  </div>
                </div>
              </div>
              <p class="font-size-md mb-2">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem.</p>
              <ul class="list-unstyled font-size-ms pt-1">
                <li class="mb-1"><span class="font-weight-medium">Purchased item:&nbsp;</span>Consequuntur magni, voluptatem sequi</li>
              </ul>
            </div> -->
            <!-- <div class="text-center">
              <button class="btn btn-outline-accent" type="button"><i class="czi-reload mr-2"></i>Load more reviews</button>
            </div> -->
          </div>
          <!-- Leave review form-->
          <!-- <div class="col-md-5 mt-2 pt-4 mt-md-0 pt-md-0">
            <div class="bg-secondary py-grid-gutter px-grid-gutter rounded-lg">
              <h3 class="h4 pb-2">Write a review</h3>
              <form class="needs-validation" method="post" novalidate>
                <div class="form-group">
                  <label for="review-name">Your name<span class="text-danger">*</span></label>
                  <input class="form-control" type="text" required id="review-name">
                  <div class="invalid-feedback">Please enter your name!</div><small class="form-text text-muted">Will be displayed on the comment.</small>
                </div>
                <div class="form-group">
                  <label for="review-email">Your email<span class="text-danger">*</span></label>
                  <input class="form-control" type="email" required id="review-email">
                  <div class="invalid-feedback">Please provide valid email address!</div><small class="form-text text-muted">Authentication only - we won't spam you.</small>
                </div>
                <div class="form-group">
                  <label for="review-rating">Rating<span class="text-danger">*</span></label>
                  <select class="custom-select" required id="review-rating">
                    <option value="">Choose rating</option>
                    <option value="5">5 stars</option>
                    <option value="4">4 stars</option>
                    <option value="3">3 stars</option>
                    <option value="2">2 stars</option>
                    <option value="1">1 star</option>
                  </select>
                  <div class="invalid-feedback">Please choose rating!</div>
                </div>
                <div class="form-group">
                  <label for="review-text">Review<span class="text-danger">*</span></label>
                  <textarea class="form-control" rows="6" required id="review-text"></textarea>
                  <div class="invalid-feedback">Please write a review!</div><small class="form-text text-muted">Your review must be at least 50 characters.</small>
                </div>
                <button class="btn btn-primary btn-shadow btn-block" type="submit">Submit a Review</button>
              </form>
            </div>
          </div> -->
        <!-- </div>
      </div> -->
    </div>

    <ProductYouMayLike />
</div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb.vue'
import ProductYouMayLike from '@/components/ProductYouMayLike.vue'
import AddToWishlistDetail from '@/components/AddToWishlistDetail.vue'
import AddToWishlistDetailGbr from '@/components/AddToWishlistDetailGbr.vue'
import carousel from 'vue-owl-carousel'
import { mapGetters, mapActions } from 'vuex'




export default {
  name: 'ShopDetail',
  components: {
      Breadcrumb,
      ProductYouMayLike,
      carousel,
      AddToWishlistDetail,
      AddToWishlistDetailGbr,


  },
  data() {
      return {
          wishlists:[],
          total_saving: 0,
          gambar_default: null,
          productDetails: {}, // objek product
          unit_nav_text1 : [
              "<img src='/img/icon/arrow-right.png' width='32px' style='background:none;' />",
              "<img src='/img/icon/arrow-right.png' width='32px' style='background:none;'/>"

          ],
          unit_nav_class:[
            'owl-prev',
            'owl-next'
          ],
          urlwindow: window.location.origin,
          base_url: process.env.VUE_APP_API_URL,
      }
  }, 
  metaInfo() {
    return { 
        title: this.productDetails.name,
        meta: [
            { name: 'description', content:  this.productDetails.about},
            { property: 'og:title', content: this.productDetails.name},
            { property: 'og:site_name', content: 'Batumuliashop.com'},
            { property: 'og:type', content: 'website'},    
            { name: 'robots', content: 'index,follow'} 
        ]
    }
  },
  created(){
    this.go()

  },
  computed: {
    ...mapGetters({
        // user    : 'auth/user',
        // prevUrl : 'prevUrl', 
        // carts   : 'cart/carts',

        user          : 'auth/user',
        provinces     : 'region/provinces',
        cities        : 'region/cities',
        // tambahkan ini
        carts         : 'cart/carts',
        countCart     : 'cart/count',
        totalPrice    : 'cart/totalPrice',
        totalSales    : 'cart/totalSales',
        totalDiscount : 'cart/totalDiscount',
         totalFreeOngkir : 'cart/totalFreeOngkir',
        totalQuantity : 'cart/totalQuantity',
        totalWeight   : 'cart/totalWeight',
        guest         : 'auth/guest',
        dialogStatus  : 'dialog/status',
        currentComponent: 'dialog/component',
        prevUrl       : 'prevUrl', 
    }),

    anotherArrayName() {
        return this.wishlists.map(item => item.products_id)
    }
  
    
  },
  methods: {
     ...mapActions({
        addCart   : 'cart/add',
        setAlert  : 'alert/set',
    }),

      isInclude(products_id) {
        return this.anotherArrayName.includes(products_id)
    },
            closeSidenavbar() {
        document.querySelector(".cz-sidebar").classList.remove("show");
        document.querySelector(".toolbar-enabled").classList.remove("offcanvas-open");
    },
     savewishlist(products_id){
        let config = {
          headers: {
            'Authorization': 'Bearer ' + this.user.api_token,
          },
        }

        let formData = {
            'products_id' : products_id,
        }

        this.axios.post('/make-wishlist', formData, config)
          .then((response) => {
              this.info = response.data.message
              this.color = response.data.status
              let {data } = response.data
              this.items = data
              this.setAlert({
                status : true,
                color  : this.color,
                text  : this.info,
              })
              // this.$router.push( { path: '/' })
          })
          .catch((error) => {
            let {data} = error.response   
            this.setAlert({
                status : true,
                color  : 'danger',
                text  : data.message,
            })
          })
      },

    buy(){
        this.addCart(this.productDetails)
        //this.$store.dispatch('cart/add', this.book)
        this.setAlert({
            status : true,
            color  : 'success',
            text   : 'Added to cart',
        })
    },
    go(){

        this.axios.get("/products",{
        params: {
        id: this.$route.params.id
      }
    })
    .then(res => (
        this.setDataPicture(res.data.data)
    ))
        //  eslint-disable-next-line no-console
    .catch(err => console.log(err));


        // let { slug } = this.$route.params
        // let url = '/products/'+slug
        // url = url + '?page='+this.page
        // url = encodeURI(url)

        // let url = '/products?id='+this.id
        // this.axios.get(url)
        // .then((response) => {
            // let { data } = response.data.data.data
        //     this.productDetails = response.data.data.data
        //     this.id = response.data.data.id
        //     // this.origins_name = response.data.origins_name
        // })
        // .catch((error) => {
        //     let { responses } = error
        //     console.log(responses)
        // }) 

    },
    changeImage(urlImage) {
      this.gambar_default = urlImage;
    },
    setDataPicture(data) {
      // replace object productDetails dengan data dari API
      this.productDetails = data;
      // replace value gambar default dengan data dari API (galleries)
      this.gambar_default = data.galleries[0].photo;
    },
    // saveKeranjang(idProduct, nameProduct, priceProduct, photoProduct) {
    //   var productStored = {
    //     "id": idProduct,
    //     "name": nameProduct,
    //     "price": priceProduct,
    //     "photo": photoProduct
    //   }
    //   this.keranjangUser.push(productStored);
    //   const parsed = JSON.stringify(this.keranjangUser);
    //   localStorage.setItem('keranjangUser', parsed);
    // }
  },
  
  mounted() {
  
    window.scrollTo(0, 0);
  }

    
}


</script>



<style scoped>
.product-thumbs .pt {
  margin-right: 10px;
}

.wishlist-active {
  color:red;
}

 span .next, span .prev {
   margin-top:35px;
   background: #000;
   
   color: #fff;
   border-radius: 5px 0 0 5px;
   position: absolute;
   z-index: 2;
 }
 span .next {
   border-radius: 5px 0 0 5px;
   padding: 5px 12px 5px 5px;
   right: 0px;
 }
 span .prev {
   border-radius: 0 5px 5px 0;
   
   padding: 5px 5px 5px 12px;
 }



/* 
    .owl-theme .owl-dots .owl-dot span {
      width: 100px;
      height: 100px;
      margin: 5px 7px;
      background: #D6D6D6;
      display: block;
      -webkit-backface-visibility: visible;
      transition: opacity 200ms ease;
      border-radius: 30px; } */


</style>




