<template>
    <!-- Profile form-->
          <form class="needs-validation tab-pane fade show active" ref="form" autocomplete="off" id="signin-tab" novalidate>
            <div class="bg-secondary rounded-lg p-4 mb-4">
              <div class="media align-items-center">
                  <img v-if="user.avatar==null && user.gender=='M'" src="/img/team/01.jpg" width="90" alt="Photo">
                  <img v-if="user.avatar==null && user.gender=='F'" src="/img/team/02.jpg" width="90" alt="Photo">
                  <img  v-if="user.avatar" :src="base_url + '/storage/images/avatar/' + user.avatar" width="90" alt="avatar" >
               
                <div class="media-body pl-3">
                  <button class="btn btn-light btn-shadow btn-sm mb-2" type="button" data-toggle="modal" data-target="#avatar"><i class="czi-loading mr-2"></i>Change avatar</button>
                  <div class="p mb-0 font-size-ms text-muted">Upload JPG, GIF or PNG image. 300 x 300 required.</div>
                </div>
              </div>
            </div>
            <div class="row" >
              
            </div>
            <div class="row" >
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="account-fn">First Name</label>
                  <input class="form-control" type="text" value="" v-model="user.name" >
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="account-ln">Last Name</label>
                  <input class="form-control" type="text" value=""  id="account-ln" v-model="user.lastname" >
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="account-email">Email Address</label>
                  <input class="form-control" type="email" id="account-email" value="" v-model="user.email" disabled>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="account-phone">Phone Number</label>
                  <input class="form-control" type="text" id="account-phone" value="" v-model="user.phone" required>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="account-phone">Gender</label>
                  <select class="form-control" v-model="gender">
                  <!-- inline object literal -->
                  <!-- <option v-if="user.gender==null" value="" selected>Choose</option> -->
                  <option v-if="user.gender=='M'" :value="M">Male</option>
                  <option v-if="user.gender=='F'" :value="F">Female</option>
                  <option v-if="user.gender!='M'" :value="M">Male</option>
                  <option v-if="user.gender!='F'" :value="F">Female</option>

                </select>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group"> 
                  <label for="account-phone">Birth Date</label>
                  <input class="form-control" type="date" id="account-phone" value="" v-model="user.birthdate">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="account-phone">Anniversary Date</label>
                  <input class="form-control" type="date" id="account-phone" value="" v-model="user.anniv_date">
                </div>
              </div>

              <div class="col-sm-6">
                <div class="form-group">
                  <label for="checkout-country">Country</label>
                  <select name="country" class="form-control custom-select" id="checkout-country" aria-readonly disabled>
                    <!-- <option>Choose country</option> -->
                    <option value="1">Indonesia</option>
                  </select>
                </div>
              </div>
              
              <!-- <div class="col-sm-6">
                <div class="form-group">
                  <label for="account-pass">New Password</label>
                  <div class="password-toggle">
                 <input class="form-control" v-model="this.password" 
                    :rules="passwordRules" :type="showPassword ? 'text' : 'password'"
                    label="Password"
                    hint="At least 6 characters"
                    counter
                    >
                     
                  <label class="password-toggle-btn" @click="showPassword = true" >
                    <input class="custom-control-input" type="checkbox" >
                    <i class="czi-eye password-toggle-indicator"></i>
                    <span class="sr-only">Show password</span>
                  </label>
                  <label class="password-toggle-btn" @click="showPassword = false" v-if="showPassword">
                    <input class="custom-control-input" type="checkbox" >
                    <i class="czi-eye password-toggle-indicator"></i>
                    <span class="sr-only">Show password</span>
                  </label>
                </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="account-confirm-pass">Confirm Password</label>
                  <div class="password-toggle">
                       <input class="form-control" v-model="copassword" 
                    :rules="copasswordRules" :type="showCoPassword ? 'text' : 'password'"
                    label="Password"
                    hint="At least 6 characters"
                    counter
                    >
                     
                  <label class="password-toggle-btn" @click="showCoPassword = true" >
                    <input class="custom-control-input" type="checkbox" >
                    <i class="czi-eye password-toggle-indicator"></i>
                    <span class="sr-only">Show password</span>
                  </label>
                  <label class="password-toggle-btn" @click="showCoPassword = false" v-if="showCoPassword">
                    <input class="custom-control-input" type="checkbox" >
                    <i class="czi-eye password-toggle-indicator"></i>
                    <span class="sr-only">Show password</span>
                  </label>
                  </div>
                
                    <small style="color:red" v-if="this.copassword!=this.password">Password does not match</small>
                      {{ this.password }}
                </div>
              </div> -->

      
      
              <div class="col-12">
                <hr class="mt-2 mb-3">
                <div class="d-flex flex-wrap justify-content-between align-items-center">
                  <div class="custom-control custom-checkbox d-block">
                    <!-- <input class="custom-control-input" type="checkbox" id="subscribe_me" checked>
                    <label class="custom-control-label" for="subscribe_me">Subscribe me to Newsletter</label> -->
                  </div>
                  <button @click="update()" class="btn btn-primary mt-3 mt-sm-0" type="button">Update profile</button>
                </div>
              </div>
            </div>
          </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AccountProfileForm',
    props: ['base_url'],

   data() {
    return {
        name: this.user.name,
        lastname: this.user.lastname,
        email: this.user.email,
        phone: this.user.phone,
        gender: this.user.gender,
        birthdate: this.user.birthdate,
        anniv_date:  this.user.anniv_date,
        showPassword: false,
        password: '',
        passwordRules: [
        v => !!v || 'Password required.',
        v => (v && v.length >= 6) || 'Min 6 characters',
        ],
        showCoPassword: false,
        copassword: '',
        copasswordRules: [
        v => !!v || 'Password required.',
        v => (v && v.length >= 6) || 'Min 6 characters',
        ],
  

      } 
  },

 
   methods: { 
      ...mapActions({
        setDialogStatus   : 'dialog/setStatus',
        setDialogComponent: 'dialog/setComponent',
        setAuth           : 'auth/set',
        setAlert          : 'alert/set',
        setProvinces      : 'region/setProvinces',
        setCities         : 'region/setCities',
      }),
      
      update(){
        let formData = new FormData()
        formData.set('name', this.user.name)
        formData.set('lastname', this.user.lastname)
        formData.set('email', this.user.email)
        formData.set('phone', this.user.phone)
        formData.set('gender', this.user.gender)
        if(this.birthdate != null){
        formData.set('birthdate', this.user.birthdate)
        }
         if(this.anniv_date != null){
        formData.set('anniv_date', this.user.anniv_date)
         }
           if(this.password != null){
        formData.set('password', this.password)
           }

        let config = {
          headers: {
            'Authorization': 'Bearer ' + this.user.api_token,
          },
        }
        this.axios.post('/update', formData, config)
           .then((response) => {
            let { data } = response
            this.setAlert({
                status : true,
                color  : 'success',
                text  : data.message,
              })
          })
          .catch(() => {
            // let {data} = error.response   
            // this.setAlert({
            //     status : true,
            //     color  : 'danger',
            //     text  : data.message,
            // })
                       this.setAuth({}) // kosongkan auth ketika logout
             this.$router.push( { path: '/account/sign-in' })
          })
      }


    },
    
   computed: {
    ...mapGetters({
      provinces     : 'region/provinces',
      cities        : 'region/cities',
      countCart     : 'cart/count',
      guest         : 'auth/guest',
      user          : 'auth/user',
      dialogStatus  : 'dialog/status',
      currentComponent: 'dialog/component',
    }),
      
   },

}
</script>