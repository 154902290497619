<template>
   <div>
     <Breadcrumb />
      <!-- Page Content-->
      <div class="container pb-5 mb-2 mb-md-4">
        <div class="row">

          <!-- Sidebar-->
        <aside class="col-lg-4 d-none d-md-none d-lg-block">
          <!-- Sidebar-->
          <div class="cz-sidebar rounded-lg box-shadow-lg" id="shop-sidebar">
            <div class="cz-sidebar-header box-shadow-sm">
              <button class="close ml-auto" type="button" data-dismiss="sidebar" aria-label="Close"><span class="d-inline-block font-size-xs font-weight-normal align-middle">Close sidebar</span><span class="d-inline-block align-middle ml-2" aria-hidden="true">&times;</span></button>
            </div>
            <div class="cz-sidebar-body">
             
              <!-- CATEGORIES -->
              <Category />

             <!-- Price range-->
              <div class="widget mb-4 pb-4 border-bottom">
                <h3 class="widget-title">Price</h3>
                  <!-- <label for="">Max</label><input type="number" class="form-control w-25 " v-model="maximum"> -->
        
                <div class="cz-range-slider" >
                  <!-- <div class="cz-range-slider-ui"></div> -->
                   <!-- <input type="range" name="" id="" class="custom-range" min="0" max="8000000" v-model="maximum"> -->
                  <div class="d-flex pb-1">
                    <div class="w-40 pr-2 mr-0">
                       <label for="">Min</label>
                      <div class="input-group input-group-sm">
                        <!-- <div class="input-group-prepend"><span class="input-group-text">Rp</span></div> -->
                        <input class="form-control cz-range-slider-value-min"  type="number" v-model="minPrice">
                      </div>
                    </div>
                    <div class="w-40 pl-1">
                       <label for="">Max</label>
                      <div class="input-group input-group-sm">
                        <!-- <div class="input-group-prepend"><span class="input-group-text">Rp</span></div> -->
                        <input class="form-control cz-range-slider-value-max" type="number" v-model="maxPrice">
                      </div>
                    </div>
                    <div class="w-20 pl-1 mt-1">
                        <label for=""></label>
                        <div class="input-group input-group-sm">
                          <button @click="filterPrice" class="btn btn-primary btn-sm">></button>
                        </div>
                    </div>
                    
                  </div>
                </div>
              </div>
              
              
              <!-- Filter by Color-->
              <!-- <div class="widget">
                <h3 class="widget-title">Color</h3>
                <div class="d-flex flex-wrap">
                  <div class="custom-control custom-option text-center mb-2 mx-1" style="width: 4rem;">
                    <input class="custom-control-input" type="checkbox" id="color-blue-gray">
                    <label class="custom-option-label rounded-circle" for="color-blue-gray"><span class="custom-option-color rounded-circle" style="background-color: #b3c8db;"></span></label>
                    <label class="d-block font-size-xs text-muted mt-n1" for="color-blue-gray">Blue-gray</label>
                  </div>
                  <div class="custom-control custom-option text-center mb-2 mx-1" style="width: 4rem;">
                    <input class="custom-control-input" type="checkbox" id="color-burgundy">
                    <label class="custom-option-label rounded-circle" for="color-burgundy"><span class="custom-option-color rounded-circle" style="background-color: #ca7295;"></span></label>
                    <label class="d-block font-size-xs text-muted mt-n1" for="color-burgundy">Burgundy</label>
                  </div>
                  <div class="custom-control custom-option text-center mb-2 mx-1" style="width: 4rem;">
                    <input class="custom-control-input" type="checkbox" id="color-teal">
                    <label class="custom-option-label rounded-circle" for="color-teal"><span class="custom-option-color rounded-circle" style="background-color: #91c2c3;"></span></label>
                    <label class="d-block font-size-xs text-muted mt-n1" for="color-teal">Teal</label>
                  </div>
                  <div class="custom-control custom-option text-center mb-2 mx-1" style="width: 4rem;">
                    <input class="custom-control-input" type="checkbox" id="color-brown">
                    <label class="custom-option-label rounded-circle" for="color-brown"><span class="custom-option-color rounded-circle" style="background-color: #9a8480;"></span></label>
                    <label class="d-block font-size-xs text-muted mt-n1" for="color-brown">Brown</label>
                  </div>
                  <div class="custom-control custom-option text-center mb-2 mx-1" style="width: 4rem;">
                    <input class="custom-control-input" type="checkbox" id="color-coral-red">
                    <label class="custom-option-label rounded-circle" for="color-coral-red"><span class="custom-option-color rounded-circle" style="background-color: #ff7072;"></span></label>
                    <label class="d-block font-size-xs text-muted mt-n1" for="color-coral-red">Coral red</label>
                  </div>
                  <div class="custom-control custom-option text-center mb-2 mx-1" style="width: 4rem;">
                    <input class="custom-control-input" type="checkbox" id="color-navy">
                    <label class="custom-option-label rounded-circle" for="color-navy"><span class="custom-option-color rounded-circle" style="background-color: #696dc8;"></span></label>
                    <label class="d-block font-size-xs text-muted mt-n1" for="color-navy">Navy</label>
                  </div>
                  <div class="custom-control custom-option text-center mb-2 mx-1" style="width: 4rem;">
                    <input class="custom-control-input" type="checkbox" id="color-charcoal">
                    <label class="custom-option-label rounded-circle" for="color-charcoal"><span class="custom-option-color rounded-circle" style="background-color: #4e4d4d;"></span></label>
                    <label class="d-block font-size-xs text-muted mt-n1" for="color-charcoal">Charcoal</label>
                  </div>
                  <div class="custom-control custom-option text-center mb-2 mx-1" style="width: 4rem;">
                    <input class="custom-control-input" type="checkbox" id="color-sky-blue">
                    <label class="custom-option-label rounded-circle" for="color-sky-blue"><span class="custom-option-color rounded-circle" style="background-color: #8bcdf5;"></span></label>
                    <label class="d-block font-size-xs text-muted mt-n1" for="color-sky-blue">Sky blue</label>
                  </div>
                </div>
              </div> -->

            </div>
          </div>
        </aside> 
        <!-- Content  -->

    <section class="col-lg-8">
          <!-- Toolbar-->
          <div class="d-flex justify-content-center justify-content-sm-between align-items-center pt-2 pb-4 pb-sm-5">
            <div class="d-flex flex-wrap">
              <div class="form-inline flex-nowrap mr-3 mr-sm-4 pb-3">
                <label class="text-light opacity-75 text-nowrap mr-2 d-none d-sm-block" for="sorting">Sort by:</label>
                <select class="form-control custom-select" id="sorting">
                  <option>Popularity</option>
                  <!-- <option>Low - Hight Price</option>
                  <option>High - Low Price</option>
                  <option>Average Rating</option>
                  <option>A - Z Order</option>
                  <option>Z - A Order</option> -->
                </select>
                <span class="font-size-sm text-light opacity-75 text-nowrap ml-2 d-none d-md-block">
                  <!-- of 287 products -->
                  </span>
              </div>
            </div>
            <div class="d-flex pb-3"><a class="nav-link-style nav-link-light mr-3" href="" ><i class="czi-arrow-left"></i></a><span class="font-size-md text-light">{{ this.page }} / {{ this.lastPage }}</span><a class="nav-link-style nav-link-light ml-3" href="#"><i class="czi-arrow-right"></i></a></div>
           
          </div>
  

          <!-- Products grid-->
          <div class="row mx-n2" v-if="products.length > 0">
            <!-- Product-->
            <div class="col-12 px-2 mb-4">
              <small style="color:#642369">{{ this.totalProduct }} Search results for "<strong>{{ this.$route.params.keyword   }}</strong>"</small>
            </div>
            
            <div  class="col-6 col-md-4 col-lg-3 px-2 mb-4" v-for="itemProduct in products" v-bind:key="itemProduct.id">
            <div  v-if="itemProduct.price > Number(maximum)" >
              
                <div class="card products product-card">
                  <small>CODE: {{ itemProduct.kode }}</small>
                  <AddToWishlist :itemProduct="itemProduct" />
                 
                  <router-link v-bind:to="'/product/detail/'+itemProduct.id+'/'+itemProduct.slug" class="card-img-top d-block overflow-hidden"  >
                  <div class="p-0 w-100 d-flex align-items-start  gbr-produk" style="background:#ccc;">
                    <!-- <img v-bind:src="itemProduct.galleries[0].photo" alt="Product" class="img-fluid mx-auto" style="float:none" > -->
                    <img :src="'/small/thumb_' + itemProduct.galleries[0].photo" alt="Product" class="img-fluid mx-auto" style="float:none" >
                  </div>
                  </router-link> 
                   
                  <div class="card-body pt-2 px-0"><a class="product-meta d-block font-size-sm" href="#"></a>
                    <h4 class="product-title"><router-link v-bind:to="'/product/detail/'+itemProduct.id+'/'+itemProduct.slug" ><b>{{ itemProduct.name }}</b></router-link><br>
                    <small>
                      <strong>{{ itemProduct.carats }}</strong> <br>
                      {{ itemProduct.dimensions }}
                    </small>
                    <div class="d-flex justify-content-between mt-0">
                      <div class="product-price">
                        <!-- <span class="text-accent font-size-lg">Rp {{ itemProduct.price.toLocaleString('id-ID') }}</span> -->
                         <div v-if="itemProduct.discount_status == 1" class="font-size-lg text-accent pt-2" > 
                            Rp {{ itemProduct.discount_price.toLocaleString('id-ID') }} <br>
                          
                            <span class="font-size-xs p-1 mr-2" style="background-color:red; color:#fff; border-radius:3px;"><strong>{{ itemProduct.discount_persen }}%</strong></span> 
                            <span class="font-size-sm" style="text-decoration: line-through;color:gray;">Rp {{ itemProduct.price.toLocaleString('id-ID') }}</span>
                        </div>
                        <div v-else class="font-size-lg text-accent pt-2">Rp {{ itemProduct.price.toLocaleString('id-ID') }}</div> 
                      </div>
                      <!-- <div class="star-rating"><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star"></i></div> -->
                    </div>
                    </h4>
                    
                  </div>

                  <!-- <div class="card-body card-body-hidden" v-if="itemProduct.quantity > 0">
                  <a @click="saveKeranjang(itemProduct.id, itemProduct.name, itemProduct.price, itemProduct.galleries[0].photo)"  class="btn btn-primary btn-sm btn-block mb-2" type="button" data-toggle="toast" data-target="#cart-toast"><i class="czi-cart font-size-sm mr-1"></i>Add to Cart</a>
                  </div>
                  <div class="card-body card-body-hidden" v-else>
                    <button class="btn btn-secondary btn-sm btn-block mb-2" type="button" data-toggle="toast" data-target="#cart-toast">Sold Out</button>
                  
                  </div> -->


                </div>
                <hr class="d-sm-none">
              </div>
            </div>
          </div>


            <div class="row" v-else>
              <div class="col-md-12 text-center" >
                <p>
                  Produk belum tersedia saat ini. {{ products.length }}
                </p>
              </div>
          </div>
          <hr class="my-3">
         
  <div class="overflow-auto">
    <!-- Use text in props -->
    <b-pagination-nav v-model="page"  @input="go"
       limit="11"
      :link-gen="linkGen"
      :number-of-pages="lastPage"
       :per-page="perPage"
      base-url="?page="
      first-text="First"
      prev-text="Prev"
      next-text="Next"
      last-text="Last"
    ></b-pagination-nav>
    </div>



          
        </section>
        </div>
      </div>


   </div>
   
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb.vue'
import Category from '@/components/Category.vue'
import { mapGetters, mapActions } from 'vuex'
import AddToWishlist from '@/components/AddToWishlist.vue'


export default {
  name: 'ShopSearchResult',


  components: {
      Breadcrumb,
      Category,
      AddToWishlist

  },
   data() {
      return {

        minPrice:0,
        maxPrice:0,
        maximum: 0,
        products: [],
        keranjangUser:[],
        page: null,
        currentPage:'',
        subcategories_id: '',

      // page: 1,
        next_page_url:'',
        nextPage:'',
        to:'',
        perPage: 21,
        lastPage: this.lastPage,
        totalProduct: this.totalProduct,
        nextPageUrl: this.next_page_url, 
        prevPageUrl: this.prev_page_url ,
        base_url: process.env.VUE_APP_API_URL,

      }
    }, 
    created(){
      this.go() 
      // this.pilih()
    },
    //  mounted() {


    //    let keyword = this.$route.params.keyword
    //   this.axios.get('/products/catalog-search/'+keyword)
    //    .then((response) => {
    //       let {data } = response.data
    //       this.products = data.data
    //        this.currentPage = data.current_page
    //         this.lastPage = data.last_page
    //         this.totalProduct = data.total
    //         this.next_page_url = data.next_page_url
    //         this.prev_page_url = data.prev_page_url
    //         this.to = data.to
    //     })
    //    .catch((error) => {
    //       let {data} = error.response
    //       this.setAlert({
    //           status : true,
    //           text  : data.message,
    //           color  : 'danger',
    //     }) })

    // },

    computed: {
        ...mapGetters({
          user    : 'auth/user',
    }),
  
  },

    methods: {
       ...mapActions({
          setAlert  : 'alert/set',
      }),

      filterPrice(){
          let keyword = this.$route.params.keyword
           if(this.$route.query.page){
              this.axios.get('/products/catalog-search/'+keyword+'?price_from='+this.minPrice +'&price_to='+this.maxPrice+'&page=1')
                .then((res) => {

                    this.products = res.data.data.data
                    this.currentPage = res.data.data.current_page
                    this.page = res.data.data.current_page
                    this.lastPage = res.data.data.last_page
                    this.totalProduct = res.data.data.total
                    this.next_page_url = res.data.data.next_page_url
                    this.prev_page_url = res.data.data.prev_page_url
                    this.to = res.data.data.to

                    this.$router.push({path: this.$route.fullPath, query: {pmin: this.minPrice, pmax: this.maxPrice, page: '1'} });
                    

            })
           }else{
                this.axios.get('/products/catalog-search/'+keyword+'?price_from='+this.minPrice +'&price_to='+this.maxPrice+'&page='+this.currentPage)
                .then((res) => {

                    this.products = res.data.data.data
                    this.currentPage = res.data.data.current_page
                    this.page = res.data.data.current_page
                    this.lastPage = res.data.data.last_page
                    this.totalProduct = res.data.data.total
                    this.next_page_url = res.data.data.next_page_url
                    this.prev_page_url = res.data.data.prev_page_url
                    this.to = res.data.data.to

                    this.$router.push({path: this.$route.fullPath, query: {pmin: this.minPrice, pmax: this.maxPrice} });
                     })
           }

    



    },

      
    
      getResults(page){
 
        if(this.$route.params==null){
             let keyword = this.$route.params.keyword
        this.axios.get('/products/catalog-search/'+keyword+'/?page=' + page)
           .then((response) => {
                  let {data} = response.data;
                  this.products = data.data;
                  this.currentPage = data.current_page
                  this.lastPage = data.last_page
                  this.totalProduct = data.total
                  this.next_page_url = data.next_page_url
                  this.prev_page_url = data.prev_page_url
                  this.to = data.to
                })
        }else{
            let keyword = this.$route.params.keyword
            this.axios.get('/products/catalog-search/'+keyword)
            .then((response) => {
                    let {data} = response.data;
                     this.products = data.data;
                })
            .catch((error) => {
                  let {data} = error.response  
                  this.setAlert({
                  status : true,
                  color  : 'danger',
                  text  : data.message,
                  })
            }); 
        }
      },

    

    linkGen(pageNum) {
        return pageNum === 1 ? '?' : `?page=${pageNum}`
      },

      go(){
          let keyword = this.$route.params.keyword
         if(this.$route.query.pmin && this.$route.query.pmin!=null && this.$route.query.pmax && this.$route.query.pmax!=null){
           this.axios.get('/products/catalog-search/'+keyword+'?page='+ this.$route.query.page +'&price_from='+this.$route.query.pmin +'&price_to='+this.$route.query.pmax)
           .then((response) => {
                  let {data} = response.data;
                  this.products = data.data
                  this.currentPage = data.current_page
                  this.page = data.current_page
                  this.lastPage = data.last_page
                  this.totalProduct = data.total
                  this.next_page_url = data.next_page_url
                  this.prev_page_url = data.prev_page_url
                  this.to = data.to
                
            })
            .catch((error) => {
                let { res } = error
                console.log(res)
            })
             this.$router.push({path: this.$route.fullPath, query: {pmin: this.$route.query.pmin, pmax: this.$route.query.pmax} });
           
         }else{


            this.axios.get('/products/catalog-search/'+keyword+'?page='+this.page)
            .then((response) => {
                  let {data} = response.data;
                  this.products = data.data
                  this.currentPage = data.current_page
                  this.lastPage = data.last_page
                  this.totalProduct = data.total
                  this.next_page_url = data.next_page_url
                  this.prev_page_url = data.prev_page_url
                  this.to = data.to
                
            })
            .catch((error) => {
                let { res } = error
                console.log(res)
            })
   
          
            
            
         }



        
      },

      




    },

    
   
};

</script>


