import Vue from 'vue'
Vue.config.devtools = true

import VueClipboard from 'vue-clipboard2'
import VueFuse from 'vue-fuse'
import './plugins/axios'
import App from '@/App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import PortalVue from 'portal-vue'
import VueMeta from 'vue-meta'
import gifFrames from 'gif-frames'




// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false

Vue.use(VueClipboard)
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(PortalVue)
Vue.use(VueFuse)
Vue.use(VueMeta)
Vue.use(VueClipboard)
Vue.use(gifFrames)



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
