<template>
   <div>
     <!-- Page Title-->
    <div class="page-title-overlap bg-dark pt-4">
      <div class="container d-lg-flex justify-content-between py-2 py-lg-3">
        <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
              <li class="breadcrumb-item"><a class="text-nowrap" href="/webpack.config.dev.js"><i class="czi-home"></i>Home</a></li>
              <li class="breadcrumb-item text-nowrap"><a href="#">Shop</a>
              </li>
              <li class="breadcrumb-item text-nowrap active" aria-current="page">crystal</li>
            </ol>
          </nav>
        </div>
        <div class="order-lg-1 pr-lg-4 text-center text-lg-left">
          <h1 class="h3 text-light mb-0">crystal</h1>
        </div>
      </div>
    </div>

      <!-- Page Content-->
      <div class="container pb-5 mb-2 mb-md-4">
        <div class="row">

          <!-- Sidebar-->
        <aside class="col-lg-4 d-none d-md-none d-lg-block">
          <!-- Sidebar-->
          <div class="cz-sidebar rounded-lg box-shadow-lg" id="shop-sidebar">
            <div class="cz-sidebar-header box-shadow-sm">
              <button class="close ml-auto" type="button" data-dismiss="sidebar" aria-label="Close"><span class="d-inline-block font-size-xs font-weight-normal align-middle">Close sidebar</span><span class="d-inline-block align-middle ml-2" aria-hidden="true">&times;</span></button>
            </div>
            <div class="cz-sidebar-body">
             


              <CategoryCrystal />

              <!-- Price range-->
              <div class="widget mb-4 pb-4 border-bottom">
                <h3 class="widget-title">Price</h3>
                  <!-- <label for="">Max</label><input type="number" class="form-control w-25 " v-model="maximum"> -->
        
                <div class="cz-range-slider" >
                  <!-- <div class="cz-range-slider-ui"></div> -->
                   <!-- <input type="range" name="" id="" class="custom-range" min="0" max="8000000" v-model="maximum"> -->
                  <div class="d-flex pb-1">
                    <div class="w-40 pr-2 mr-0">
                       <label for="">Min</label>
                      <div class="input-group input-group-sm">
                        <!-- <div class="input-group-prepend"><span class="input-group-text">Rp</span></div> -->
                        <input class="form-control cz-range-slider-value-min"  type="number" v-model="minPrice">
                      </div>
                    </div>
                    <div class="w-40 pl-1">
                       <label for="">Max</label>
                      <div class="input-group input-group-sm">
                        <!-- <div class="input-group-prepend"><span class="input-group-text">Rp</span></div> -->
                        <input class="form-control cz-range-slider-value-max" type="number" v-model="maxPrice">
                      </div>
                    </div>
                    <div class="w-20 pl-1 mt-1">
                        <label for=""></label>
                        <div class="input-group input-group-sm">
                          <button @click="filterPrice" class="btn btn-primary btn-sm">></button>
                        </div>
                    </div>
                    
                  </div>
                </div>
              </div>
              
              
              <!-- Filter by Color-->
              <!-- <div class="widget">
                <h3 class="widget-title">Color</h3>
                <div class="d-flex flex-wrap">
                  <div class="custom-control custom-option text-center mb-2 mx-1" style="width: 4rem;">
                    <input class="custom-control-input" type="checkbox" id="color-blue-gray">
                    <label class="custom-option-label rounded-circle" for="color-blue-gray"><span class="custom-option-color rounded-circle" style="background-color: #b3c8db;"></span></label>
                    <label class="d-block font-size-xs text-muted mt-n1" for="color-blue-gray">Blue-gray</label>
                  </div>
                  <div class="custom-control custom-option text-center mb-2 mx-1" style="width: 4rem;">
                    <input class="custom-control-input" type="checkbox" id="color-burgundy">
                    <label class="custom-option-label rounded-circle" for="color-burgundy"><span class="custom-option-color rounded-circle" style="background-color: #ca7295;"></span></label>
                    <label class="d-block font-size-xs text-muted mt-n1" for="color-burgundy">Burgundy</label>
                  </div>
                  <div class="custom-control custom-option text-center mb-2 mx-1" style="width: 4rem;">
                    <input class="custom-control-input" type="checkbox" id="color-teal">
                    <label class="custom-option-label rounded-circle" for="color-teal"><span class="custom-option-color rounded-circle" style="background-color: #91c2c3;"></span></label>
                    <label class="d-block font-size-xs text-muted mt-n1" for="color-teal">Teal</label>
                  </div>
                  <div class="custom-control custom-option text-center mb-2 mx-1" style="width: 4rem;">
                    <input class="custom-control-input" type="checkbox" id="color-brown">
                    <label class="custom-option-label rounded-circle" for="color-brown"><span class="custom-option-color rounded-circle" style="background-color: #9a8480;"></span></label>
                    <label class="d-block font-size-xs text-muted mt-n1" for="color-brown">Brown</label>
                  </div>
                  <div class="custom-control custom-option text-center mb-2 mx-1" style="width: 4rem;">
                    <input class="custom-control-input" type="checkbox" id="color-coral-red">
                    <label class="custom-option-label rounded-circle" for="color-coral-red"><span class="custom-option-color rounded-circle" style="background-color: #ff7072;"></span></label>
                    <label class="d-block font-size-xs text-muted mt-n1" for="color-coral-red">Coral red</label>
                  </div>
                  <div class="custom-control custom-option text-center mb-2 mx-1" style="width: 4rem;">
                    <input class="custom-control-input" type="checkbox" id="color-navy">
                    <label class="custom-option-label rounded-circle" for="color-navy"><span class="custom-option-color rounded-circle" style="background-color: #696dc8;"></span></label>
                    <label class="d-block font-size-xs text-muted mt-n1" for="color-navy">Navy</label>
                  </div>
                  <div class="custom-control custom-option text-center mb-2 mx-1" style="width: 4rem;">
                    <input class="custom-control-input" type="checkbox" id="color-charcoal">
                    <label class="custom-option-label rounded-circle" for="color-charcoal"><span class="custom-option-color rounded-circle" style="background-color: #4e4d4d;"></span></label>
                    <label class="d-block font-size-xs text-muted mt-n1" for="color-charcoal">Charcoal</label>
                  </div>
                  <div class="custom-control custom-option text-center mb-2 mx-1" style="width: 4rem;">
                    <input class="custom-control-input" type="checkbox" id="color-sky-blue">
                    <label class="custom-option-label rounded-circle" for="color-sky-blue"><span class="custom-option-color rounded-circle" style="background-color: #8bcdf5;"></span></label>
                    <label class="d-block font-size-xs text-muted mt-n1" for="color-sky-blue">Sky blue</label>
                  </div>
                </div>
              </div> -->

            </div>
          </div>
        </aside>
        <!-- Content  -->

     <section class="col-lg-8">
          <!-- Toolbar-->
          <div class="d-flex justify-content-center justify-content-sm-between align-items-center pt-2 pb-4 pb-sm-5">
            <div class="d-flex flex-wrap">
              <div class="form-inline flex-nowrap mr-3 mr-sm-4 pb-3">
                <label class="text-light opacity-75 text-nowrap mr-2 d-none d-sm-block" for="sorting">Sort by:</label>
                <select class="form-control custom-select" id="sorting">
                  <option>Popularity</option>
                  <!-- <option>Low - Hight Price</option>
                  <option>High - Low Price</option>
                  <option>Average Rating</option>
                  <option>A - Z Order</option>
                  <option>Z - A Order</option> -->
                </select>
                <span class="font-size-sm text-light opacity-75 text-nowrap ml-2 d-none d-md-block">
                  <!-- of 287 products -->
                  </span>
              </div>
            </div>
            <div class="d-flex pb-3"><a class="nav-link-style nav-link-light mr-3" href="" ><i class="czi-arrow-left"></i></a><span class="font-size-md text-light">{{ this.page }} / {{ this.lastPage }}</span><a class="nav-link-style nav-link-light ml-3" href="#"><i class="czi-arrow-right"></i></a></div>
           
          </div>

  

       <!-- {{ this.subcategories_id }}<br> -->
       <!-- {{ this.$route.params.id }} -->

          <!-- Products grid-->
          <div class="row mx-n2" v-if="products.length > 0">
            <!-- Product-->
            
              <div  class="col-6 col-md-4 col-lg-3 px-2 mb-4" v-for="itemProduct in products" v-bind:key="itemProduct.id">
            <div  v-if="itemProduct.price > Number(maximum) || itemProduct.subcategories_id == this.$route.params.id" >
              
                <div class="card products product-card">
                  <small>CODE: {{ itemProduct.kode }}</small>
                  <AddToWishlist :itemProduct="itemProduct" />

                  <router-link v-bind:to="'/product/detail/'+itemProduct.id+'/'+itemProduct.slug" class="card-img-top d-block overflow-hidden"  >
                  <div class="p-0 w-100 d-flex align-items-start gbr-produk" style="background:#ccc;">
                      <img :src="'/small/thumb_' + itemProduct.galleries[0].photo" alt="Product" class="img-fluid mx-auto" style="float:none" >
                  </div>
                  </router-link> 
                   
                  <div class="card-body pt-2 px-0"><a class="product-meta d-block font-size-sm" href="#"></a>
                    <h4 class="product-title"><router-link v-bind:to="'/product/detail/'+itemProduct.id+'/'+itemProduct.slug" ><b>{{ itemProduct.name }}</b></router-link><br>
                    <small>
                      <strong>{{ itemProduct.carats }}</strong> <br>
                      {{ itemProduct.dimensions }}
                    </small>
                    <div class="d-flex justify-content-between mt-0">
                      <div class="product-price">
                        <!-- <span class="text-accent font-size-lg">Rp {{ itemProduct.price.toLocaleString('id-ID') }}</span> -->
                         <div v-if="itemProduct.discount_status == 1" class="font-size-lg text-accent pt-2" > 
                            Rp {{ itemProduct.discount_price.toLocaleString('id-ID') }} <br>
                          
                            <span class="font-size-xs p-1 mr-2" style="background-color:red; color:#fff; border-radius:3px;"><strong>{{ itemProduct.discount_persen }}%</strong></span> 
                            <span class="font-size-sm" style="text-decoration: line-through;color:gray;">Rp {{ itemProduct.price.toLocaleString('id-ID') }}</span>
                        </div>
                        <div v-else class="font-size-lg text-accent pt-2">Rp {{ itemProduct.price.toLocaleString('id-ID') }}</div> 
                      
                      </div>
                      <!-- <div class="star-rating"><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star"></i></div> -->
                    </div>
                    </h4>
                    
                  </div>

                  <!-- <div class="card-body card-body-hidden" v-if="itemProduct.quantity > 0">
                  <a @click="saveKeranjang(itemProduct.id, itemProduct.name, itemProduct.price, itemProduct.galleries[0].photo)"  class="btn btn-primary btn-sm btn-block mb-2" type="button" data-toggle="toast" data-target="#cart-toast"><i class="czi-cart font-size-sm mr-1"></i>Add to Cart</a>
                  </div>
                  <div class="card-body card-body-hidden" v-else>
                    <button class="btn btn-secondary btn-sm btn-block mb-2" type="button" data-toggle="toast" data-target="#cart-toast">Sold Out</button>
                  
                  </div> -->


                </div>
                <hr class="d-sm-none">
              </div>
            </div>
          </div>


            <div class="row" v-else>
              <div class="col-md-12 text-center" >
                <p>
                  Produk belum tersedia saat ini. {{ products.length }}
                </p>
              </div>
          </div>
          <hr class="my-3">
           <!-- <pagination :data="products" @pagination-change-page="getResult"></pagination> -->
          <!-- Pagination-->
          <!-- <nav class="d-flex justify-content-between pt-2" aria-label="Page navigation">
            <ul class="pagination">
              <li class="page-item"><a class="page-link" href="#"><i class="czi-arrow-left mr-2"></i>Prev</a></li>
            </ul>
            <ul class="pagination">
              <li class="page-item d-sm-none"><span class="page-link page-link-static">1 / 5</span></li>
              <li class="page-item active d-none d-sm-block" aria-current="page"><span class="page-link">1<span class="sr-only">(current)</span></span></li>
              <li class="page-item d-none d-sm-block"><a class="page-link" href="#">2</a></li>
              <li class="page-item d-none d-sm-block"><a class="page-link" href="#">3</a></li>
              <li class="page-item d-none d-sm-block"><a class="page-link" href="#">4</a></li>
              <li class="page-item d-none d-sm-block"><a class="page-link" href="#">5</a></li>
            </ul>
            <ul class="pagination">
              <li class="page-item"><a class="page-link" href="#" aria-label="Next">Next<i class="czi-arrow-right ml-2"></i></a></li>
            </ul>
          </nav> -->
   <div v-if="this.$route.query.pmin && this.$route.query.pmax" class="overflow-auto">
    <!-- Use text in props -->
    <b-pagination-nav v-model="page"  @input="go"
    limit="11"
      :number-of-pages="lastPage"
       :per-page="perPage"
       :base-url="'?pmin='+this.$route.query.pmin+'&pmax='+this.$route.query.pmax+'&page='"
      first-text="First"
      prev-text="Prev"
      next-text="Next"
      last-text="Last"
    ></b-pagination-nav>
    </div>
  <div v-else class="overflow-auto">
    <!-- Use text in props -->
    <b-pagination-nav v-model="page"  @input="go"
    limit="11"
    :link-gen="linkGen"
      :number-of-pages="lastPage"
       :per-page="perPage"
      base-url="?page="
      first-text="First"
      prev-text="Prev"
      next-text="Next"
      last-text="Last"
    ></b-pagination-nav>
    </div>


          
        </section>


          <!-- <Sidebar /> -->
          <!-- <ProductGrid /> -->
        </div>
      </div>


   </div>
   
</template>

<script>
// import Breadcrumb from '@/components/Breadcrumb.vue'
import CategoryCrystal from '@/components/CategoryCrystal.vue'
import { mapGetters, mapActions } from 'vuex'
import AddToWishlist from '@/components/AddToWishlist.vue'

// import Sidebar from '@/components/Sidebar.vue'
// import ProductGrid from '@/components/ProductGrid.vue'

export default {
  name: 'ShopSubcrystal',
  components: {
      // Breadcrumb,
      CategoryCrystal,
      AddToWishlist,
      // ProductGrid,
      // Sidebar
  },
   data() {
      return {
        categories: [],
        minPrice:0,
        maxPrice:0,
        maximum: 0,
        products: [],
        keranjangUser:[],
           page: null,
        currentPage:'',
        base_url: process.env.VUE_APP_API_URL,
        
        // page: 1,
        next_page_url:'',
        nextPage:'',
        to:'',
        perPage: 21,
        lastPage: this.lastPage,
        totalProduct: this.totalProduct,
        nextPageUrl: this.next_page_url, 
        prevPageUrl: this.prev_page_url,
       


      }
    }, 
    computed: {
        ...mapGetters({
          user    : 'auth/user',
        }),
          anotherArrayName() {
            return this.wishlists.map(item => item.products_id)
        }
  
    },

  
    created(){


    
      
      // this.subcategories_id=this.$route.params.id;
      // this.go(this.$route.params.id)

      let url = '/products-crystal?subcategories_id=' + this.$route.params.id
        this.axios.get(url)
        //  this.axios.get("/products",{
        //     params: {
        //     subcategories_id: this.$route.params.id
        //   }

        //  })
        .then((res) => {

            this.products = res.data.data.data
            this.currentPage = res.data.data.current_page
            this.lastPage = res.data.data.last_page
            this.totalProduct = res.data.data.total
            this.next_page_url = res.data.data.next_page_url
            this.prev_page_url = res.data.data.prev_page_url
            this.$forceUpdate('/product/crystal/'+ this.$route.params.id +'/'+ this.$route.params.slug)
               
        
             

        })
        .catch((error) => {
            let { res } = error
            console.log(res)
        })
        
    },
   
    methods: {

       filterPrice(){
        if(this.$route.query.page){

            this.axios.get('/products-crystal?subcategories_id=' + this.$route.params.id+'price_from='+this.minPrice +'&price_to='+this.maxPrice+'&page=1')
              .then((res) => {

                  this.products = res.data.data.data
                  this.lastPage = res.data.data.last_page
                  this.currentPage = res.data.data.current_page
                  this.page = res.data.data.current_page
                  this.totalProduct = res.data.data.total
                  this.next_page_url = res.data.data.next_page_url
                  this.prev_page_url = res.data.data.prev_page_url
                  this.to = res.data.data.to

                  this.$router.push({path: this.$route.fullPath, query: {pmin: this.minPrice, pmax: this.maxPrice, page: '1'} });
                  
              })

         }else{

              this.axios.get('/products-crystal?subcategories_id=' + this.$route.params.id+'price_from='+this.minPrice +'&price_to='+this.maxPrice+'&page='+this.currentPage)
              .then((res) => {

                  this.products = res.data.data.data
                  this.currentPage = res.data.data.current_page
                  this.page = res.data.data.current_page
                  this.lastPage = res.data.data.last_page
                  this.totalProduct = res.data.data.total
                  this.next_page_url = res.data.data.next_page_url
                  this.prev_page_url = res.data.data.prev_page_url
                  this.to = res.data.data.to

                  this.$router.push({path: this.$route.fullPath, query: {pmin: this.minPrice, pmax: this.maxPrice} });
                  
              })

         }


    



    },

     linkGen(pageNum) {
        return pageNum === 1 ? '?' : `?page=${pageNum}`
      },

     go(){
       let cat = this.$route.params.id
        if(this.$route.query.pmin && this.$route.query.pmin!=null && this.$route.query.pmax && this.$route.query.pmax!=null){
           this.axios.get('/products-crystal?crystal_id='+cat+'&page='+ this.$route.query.page +'&price_from='+this.$route.query.pmin +'&price_to='+this.$route.query.pmax)
                 .then((response) => {
                    let {data} = response.data;
                    this.products = data.data;
                    this.currentPage = data.current_page
                    this.page = data.current_page
                    this.lastPage = data.last_page
                    this.totalProduct = data.total
                    this.next_page_url = data.next_page_url
                    this.prev_page_url = data.prev_page_url
                    this.to = data.to
     
                })
                .catch((error) => {
                  let {data} = error.response  
                  this.setAlert({
                  status : true,
                  color  : 'danger',
                  text  : data.message,
                  })
            }); 
             this.$router.push({path: this.$route.fullPath, query: {pmin: this.$route.query.pmin, pmax: this.$route.query.pmax} });
           
         }else{

            this.axios.get('/products-crystal?crystal_id='+cat+'&page=' +this.page)
            .then((response) => {
                    let {data} = response.data;
                    this.products = data.data;
                    this.currentPage = data.current_page
                    this.lastPage = data.last_page
                    this.totalProduct = data.total
                    this.next_page_url = data.next_page_url
                    this.prev_page_url = data.prev_page_url
                    this.to = data.to
     
                })
            .catch((error) => {
                  let {data} = error.response  
                  this.setAlert({
                  status : true,
                  color  : 'danger',
                  text  : data.message,
                  })
            }); 
         }
      
      
        
        
      },
        ...mapActions({
          setAlert  : 'alert/set',
      }),

 
      // saveKeranjang(idProduct, nameProduct, priceProduct, photoProduct) {
      //       var productStored = {
      //         "id": idProduct,
      //         "name": nameProduct,
      //         "price": priceProduct,
      //         "photo": photoProduct
      //       }
      //       this.keranjangUser.push(productStored);
      //       const parsed = JSON.stringify(this.keranjangUser);
      //       localStorage.setItem('keranjangUser', parsed);
      // },
      getResults(page){
 


        // if(this.$route.params==null){
        //      let cat = this.$route.params.id
        // this.axios.get('/products-crystal?subcategories_id='+cat+'&page=' + page)
        //    .then((response) => {
        //             let {data} = response.data;
        //              this.products = data.data;
        //         })
        // }else{
            let cat = this.$route.params.id
            this.axios.get('/products-crystal?crystal_id='+cat+'&page=' + page)
            .then((response) => {
                    let {data} = response.data;
                     this.products = data.data;
                })
            .catch((error) => {
                  let {data} = error.response  
                  this.setAlert({
                  status : true,
                  color  : 'danger',
                  text  : data.message,
                  })
            }); 
        // }
      },

      
    },
      mounted() {
 
        this.axios.get("/categories-crystal")
        .then(res => (this.categories = res.data.data))
        //  eslint-disable-next-line no-console
        .catch(err => console.log(err));


      //    let config = {
      //       headers: {
      //           'Authorization': 'Bearer ' + this.user.api_token,
      //       },
      //     }
      
      // this.axios.get('/my-wishlist',config)
      //  .then((response) => {
      //     let {data } = response.data
      //     this.wishlists = data
      //   })
      //  .catch((error) => {
      //     let {data} = error.response
      //     this.setAlert({
      //         status : true,
      //         text  : data.message,
      //         color  : 'danger',
      //   }) })
    },
   
   
};

</script>


<style scoped>

/* .wishlist-active {
  color:red;
} */

</style>

