<template>
<div>
   <!-- Page Title-->
    <div class="page-title-overlap bg-dark pt-4">
      <div class="container d-lg-flex justify-content-between py-2 py-lg-3">
        <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
              <li class="breadcrumb-item"><a class="text-nowrap" href="index.html"><i class="czi-home"></i>Home</a></li>
              <li class="breadcrumb-item text-nowrap"><a href="shop-grid-ls.html">Shop</a>
              </li>
              <li class="breadcrumb-item text-nowrap active" aria-current="page">Cart</li>
            </ol>
          </nav>
        </div>
        <div class="order-lg-1 pr-lg-4 text-center text-lg-left">
          <h1 class="h3 text-light mb-0">Your cart</h1>
        </div>
      </div>
    </div>
    <!-- Page Content-->
    <div class="container pb-5 mb-2 mb-md-4">
      <div class="row">
        <!-- List of items-->
        <section class="col-lg-8">
          <div class="d-flex justify-content-between align-items-center pt-3 pb-2 pb-sm-5 mt-1">
            <h2 class="h6 text-light mb-0">Products</h2>
            <router-link class="btn btn-outline-primary btn-sm pl-2" to="/product"><i class="czi-arrow-left mr-2"></i>Continue shopping</router-link>
          </div>
          <!-- Item-->
          <div v-if="countCart>0">
          <div class="d-sm-flex justify-content-between align-items-center my-4 pb-3 border-bottom" v-for="(item, index) in carts"  v-bind:key="'cart'+index" >
            <div class="media media-ie-fix d-block d-sm-flex align-items-center text-center text-sm-left">
              <router-link class="d-inline-block mx-auto mr-sm-4" v-bind:to="'/product/detail/'+item.id+'/'+item.slug" style="width: 10rem;">
              <img :src="base_url + '/storage/images/products/' + item.photo" alt="Product"></router-link>
              <div class="media-body pt-2">
                <h3 class="product-title font-size-base mb-2"><router-link v-bind:to="'/product/detail/'+item.id+'/'+item.slug">{{ item.name }}</router-link></h3> 
                <div class="font-size-xs"><span class="text-muted mr-2">Weight:</span><span>{{ item.carats }}</span></div>
                <div class="font-size-xs"><span class="text-muted mr-2">Dimension:</span><span>{{ item.dimensions }}</span></div>
                <div v-if="item.discount_status == 1" class="font-size-lg text-accent pt-2" > 
                    Rp {{ item.discount_price.toLocaleString('id-ID') }} <br>
                    <span class="badge badge-danger mr-2">{{ item.discount_persen }}% </span> 
                    <span class="font-size-sm" style="text-decoration: line-through;color:gray;">Rp {{ item.price.toLocaleString('id-ID') }}</span>
                </div>
                <div v-else class="font-size-lg text-accent pt-2">Rp {{ item.price.toLocaleString('id-ID') }}</div> 
                
              </div>
            </div>
            <div class="pt-2 pt-sm-0 pl-sm-3 mx-auto mx-sm-0 text-center text-sm-left" style="max-width: 9rem;">
              <div class="form-group mb-0">
                <label class="font-weight-medium" for="quantity1">Quantity</label>
                <input class="form-control" type="number" id="quantity1" v-bind:value="item.quantity" disabled>
              </div>
              <button @click="removeCart(item)" class="btn btn-link px-0 text-danger"><i class="czi-close-circle mr-2"></i><span class="font-size-sm">Remove</span></button>
            </div>
          </div>
          </div>
        


          <!-- <button class="btn btn-outline-accent btn-block" type="button"><i class="czi-loading font-size-base mr-2"></i>Update cart</button> -->
        </section>
        <!-- Sidebar-->
        <aside class="col-lg-4 pt-4 pt-lg-0">
          <div class="cz-sidebar-static rounded-lg box-shadow-lg ml-lg-auto">
            <div class="text-center mb-4 pb-3 border-bottom">
              <h2 class="h6 mb-3 pb-1">Subtotal</h2>
              <h3 class="font-weight-normal">Rp {{ totalSales.toLocaleString('id-ID') }}</h3>
            </div>
            <div class="form-group mb-4">
              <!-- <label class="mb-3" for="order-comments"><span class="badge badge-info font-size-xs mr-2">Note</span><span class="font-weight-medium">Additional comments</span></label>
              <textarea class="form-control" rows="6" id="order-comments"></textarea> -->
            </div>
            <div class="accordion" id="order-options">
              <!-- <div class="card">
                <div class="card-header">
                  <h3 class="accordion-heading"><a href="#promo-code" role="button" data-toggle="collapse" aria-expanded="true" aria-controls="promo-code">Apply promo code<span class="accordion-indicator"></span></a></h3>
                </div>
                <div class="collapse show" id="promo-code" data-parent="#order-options">
                  <form class="card-body needs-validation" method="post" novalidate>
                    <div class="form-group">
                      <input class="form-control" type="text" placeholder="Promo code" required>
                      <div class="invalid-feedback">Please provide promo code.</div>
                    </div>
                    <button class="btn btn-outline-primary btn-block" type="submit">Apply promo code</button>
                  </form>
                </div>
              </div> -->
              <!-- <div class="card">
                <div class="card-header">
                  <h3 class="accordion-heading"><a class="collapsed" href="#shipping-estimates" role="button" data-toggle="collapse" aria-expanded="true" aria-controls="shipping-estimates">Shipping estimates<span class="accordion-indicator"></span></a></h3>
                </div>
                <div class="collapse" id="shipping-estimates" data-parent="#order-options">
                  <div class="card-body">
                    <form class="needs-validation" novalidate>
                      <div class="form-group">
                        <select class="form-control custom-select" required>
                          <option value="">Choose your country</option>
                          <option value="Australia">Australia</option>
                          <option value="Belgium">Belgium</option>
                          <option value="Canada">Canada</option>
                          <option value="Finland">Finland</option>
                          <option value="Mexico">Mexico</option>
                          <option value="New Zealand">New Zealand</option>
                          <option value="Switzerland">Switzerland</option>
                          <option value="United States">United States</option>
                        </select>
                        <div class="invalid-feedback">Please choose your country!</div>
                      </div>
                      <div class="form-group">
                        <select class="form-control custom-select" required>
                          <option value="">Choose your city</option>
                          <option value="Bern">Bern</option>
                          <option value="Brussels">Brussels</option>
                          <option value="Canberra">Canberra</option>
                          <option value="Helsinki">Helsinki</option>
                          <option value="Mexico City">Mexico City</option>
                          <option value="Ottawa">Ottawa</option>
                          <option value="Washington D.C.">Washington D.C.</option>
                          <option value="Wellington">Wellington</option>
                        </select>
                        <div class="invalid-feedback">Please choose your city!</div>
                      </div>
                      <div class="form-group">
                        <input class="form-control" type="text" placeholder="ZIP / Postal code" required>
                        <div class="invalid-feedback">Please provide a valid zip!</div>
                      </div>
                      <button class="btn btn-outline-primary btn-block" type="submit">Calculate shipping</button>
                    </form>
                  </div>
                </div>
              </div> -->
            </div><router-link class="btn btn-primary btn-shadow btn-block mt-4" to="/checkout/details"><i class="czi-card font-size-lg mr-2"></i>Proceed to Checkout</router-link>
          </div>
        </aside>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'ShopCart',
  data(){
    return{
      urlwindow: window.location.origin,
      base_url: process.env.VUE_APP_API_URL,
    }
  },

  computed: {
      ...mapGetters({
        carts         : 'cart/carts',
        countCart     : 'cart/count',
        totalPrice    : 'cart/totalPrice',
        totalSales    : 'cart/totalSales',
        totalQuantity : 'cart/totalQuantity',
        totalDiscount    : 'cart/totalDiscount',
        totalFreeOngkir : 'cart/totalFreeOngkir',
        totalWeight   : 'cart/totalWeight',
        guest         : 'auth/guest',
        user          : 'auth/user',
        dialogStatus  : 'dialog/status',
        currentComponent: 'dialog/component',
        prevUrl : 'prevUrl', 

      }),
    },
    methods: {
        ...mapActions({
          setDialogStatus   : 'dialog/setStatus',
          setDialogComponent: 'dialog/setComponent',
          setAuth     : 'auth/set',
          setAlert    : 'alert/set',
          addCart     : 'cart/add',
          removeCart  : 'cart/remove',
          setCart     : 'cart/set',
        }),
        checkout(){
            this.close()
            this.$router.push({path: "/checkout"})
        },
        close() {
            this.$emit('closed', false)
        },
  
        dialog:{
          get () {
            return this.dialogStatus
          },
          set (value) {
            this.setDialogStatus(value)
          }
        }

    },
    
  // components: {
 
  // },

  // data() {
  //   return {
  //     keranjangUser: []

  //   }
  // },
  // methods: {
  //   removeItem(index) {
  //     this.keranjangUser.splice(index,1);
  //     const parsed = JSON.stringify(this.keranjangUser);
  //     localStorage.setItem('keranjangUser', parsed);
  //   }
  // },
  // mounted() {
  //   if (localStorage.getItem('keranjangUser')) {
  //   try {
  //     this.keranjangUser = JSON.parse(localStorage.getItem('keranjangUser'));
  //   } catch(e) {
  //     localStorage.removeItem('keranjangUser');
  //   }
  //   }
  // },
  //   computed: {
  //   totalHarga() {
  //     return this.keranjangUser.reduce(function(items, data){
  //       return items+data.price;
  //     },0);
  //   }
  // }

}




</script>

<style scoped>
  
</style>