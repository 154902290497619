<template>
<div class="btn-add-wishlist">

<!-- {{ this.iswishlist }} -->

         <button class="btn-wishlist btn-sm" type="button" data-toggle="tooltip" data-placement="left" title="Add to wishlist" @click="savewishlist(itemProduct.id)"> 
          <span v-if="isloading" >
            <PreloaderWishlist v-if="!isInclude(itemProduct.id)"  :iswishlist="iswishlist" />
             <!-- <PreloaderUnwish  v-if="isInclude(itemProduct.id)"   :iswishlist="iswishlist" /> -->
             <i  v-if="isInclude(itemProduct.id)" class="fa fa-heart " aria-hidden="true"></i>
            </span>
          <i  v-if="!isloading" class="fa fa-heart " :class="isInclude(itemProduct.id)?'wishlist-active':''"  aria-hidden="true"></i>

          </button> 
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PreloaderWishlist from './preloader/PreloaderWishlist.vue'
// import PreloaderUnwish from './preloader/PreloaderUnwish.vue'
import $ from 'jquery'


export default {
  name: 'AddToWishlist',
  props: ['itemProduct'],
  components: {
    PreloaderWishlist,
    // PreloaderUnwish
 
  },
   data() {
      return {
        iswishlist:null,
        isloading:false
      }
    }, 
    computed: {
        ...mapGetters({
        countCart : 'cart/count',
        wishlists  : 'wishlist/wishlists',
        guest : 'auth/guest',
        user : 'auth/user',
        dialogStatus  : 'dialog/status',
        currentComponent: 'dialog/component',
        prevUrl : 'prevUrl', 
        }), 
         anotherArrayName() {

        return this.wishlists.map(item => item.products_id)
        },
  
    },

    methods: {
      ...mapActions({
      setDialogStatus     : 'dialog/setStatus',
      setDialogComponent  : 'dialog/setComponent',
      setAuth             : 'auth/set',
      setAlert            : 'alert/set',
      setWishlists        : 'wishlist/setWishlists',
    }),
     isInclude(products_id) {
      
     
        return this.anotherArrayName.includes(products_id)
        
    },
     openModal () {
      $('#signin-modal').modal('show')
    },
      
     savewishlist(products_id){
        let config = {
          headers: {
            'Authorization': 'Bearer ' + this.user.api_token,
          },
        }

        let formData = {
            'products_id' : products_id,
        }
        this.isloading = true
        this.axios.post('/make-wishlist', formData, config)
          .then((response) => {
            this.info = response.data.message
            this.color = response.data.status
          let {data } = response.data
          this.items = data
            this.setAlert({
                status : true,
                color  : this.color,
                text  : this.info,
              })
           
            let config = {
                            headers: {
                            'Authorization': 'Bearer ' + this.user.api_token,
                            },
                        }
                        this.axios.get('/my-wishlist', config)
                         .then((response) => {
                            let { data } = response.data
                            this.setWishlists(data)
                             this.isloading = false
                           
                        //     this.setAlert({
                        //     status : true,
                        //     color  : 'success',
                        //     text  : 'wishlist data',
                        // })
                         })

          })
          .catch(() => {
            // let {data} = error.response   
            // this.setAlert({
            //     status : true,
            //     color  : 'danger',
            //     text  : data.message,
            // })
            this.openModal()
          })
      },




    },


    
   
};

</script>

<style scoped>
.wishlist-active {
  color:red;
}
.preload-wishlist-small{
 margin-right:50px;margin-top:0px; padding:0; width:30px; height: 30px;
}
</style>