<template>
    <!-- Page Content-->
    <div class="container py-4 py-lg-5 my-4">
      <div class="row">
                <div class="col-md-6 pt-4 mt-3 mt-md-0">
          <h2 class="h4 mb-3">Sign up</h2>
          <p class="font-size-sm text-muted mb-4">Registration takes less than a minute but gives you full control over your orders.</p>
          <form class="needs-validation" ref="form" :v-model="valid" lazy-validation autocomplete="off" novalidate id="signup-tab">
    <div class="form-group">
                <label for="su-name">Full name</label>
                <input class="form-control" v-model="name"
          :rules="nameRules"
          :counter="255"
          label="Name"
          type="text" id="su-name" placeholder="John Doe" required>
                <div class="invalid-feedback">Please fill in your name.</div>
              </div>
              <div class="form-group">
                <label for="su-email">Email address</label>
                <input class="form-control" v-model="email" :rules="emailRules" label="E-mail" type="email" id="su-email" placeholder="johndoe@example.com" required>
                <div class="invalid-feedback">Please provide a valid email address.</div>
              </div>
              <div class="form-group">
                <label for="su-email">Phone</label>
                <input class="form-control" v-model="phone" label="Phone" type="text" id="su-phone" placeholder="0812xxxxxxx" required>
                <div class="invalid-feedback">Please provide a valid phone number.</div>
              </div>
              <div class="form-group">
                <label for="su-email">Gender</label>
                <select class="form-control" v-model="gender">
                  <!-- inline object literal -->
                  <option :value="M">Male</option>
                  <option :value="F">Female</option>
                </select>
              </div>
              <div class="form-group">
                <label for="su-email">Birth Date</label>
                <input class="form-control" v-model="birthdate" label="Date" type="date" id="su-birthdate"  required>
              </div>
              <div class="form-group">
                <label for="su-email">Anniversary Date</label>
                <input class="form-control" v-model="anniv_date" label="Date" type="date" id="su-annivesary"  required>
              </div>

              <div class="form-group">
                <label for="su-password">Password</label>
                <div class="password-toggle">
                  <input class="form-control"  v-model="password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="passwordRules"
                    :type="showPassword ? 'text' : 'password'"
                    label="Password"
                    hint="At least 6 characters"
                    counter
                    @click:append="showPassword = !showPassword" id="su-password" required>
                  <!-- <label class="password-toggle-btn">
                    <input class="custom-control-input" type="checkbox"><i class="czi-eye password-toggle-indicator"></i><span class="sr-only">Show password</span>
                  </label> -->
                </div>
              </div>
               <div class="form-group">
                <label for="su-email">Shipping Address</label>
                <input class="form-control mb-2" v-model="address_line1" type="text" placeholder="Address Line1*" required>
                <input class="form-control mb-2" v-model="address_line2" type="text" placeholder="Address Line2" required>
                <input class="form-control" v-model="address_line3" type="text" placeholder="Address Line3" required>
                <div class="invalid-feedback">Please provide a valid address.</div>
              </div>
               <div class="form-group">
                 <div class="row">
                   <div class="col-6">
                      <label for="checkout-province">Province</label>
                <select name="province" class="form-control custom-select" id="checkout-province" aria-readonly  v-model="province_id" @change="citiesByProvince()" >
                  <option value="">Choose province</option>
                  <option v-for="province in provinces" :key="province.id" :value="province.id">{{ province.province }}</option>
                </select>
                     
                   </div>
                   <div class="col-6">
                     <label for="su-email">City</label>
                     <select class="form-control custom-select" id="checkout-city" v-model='city_id'>
                      <option value="">Choose city</option>
                      <option class="py-1" v-for="city in citiesByProvince" :key="city.id" :value="city.id">{{ city.city }}</option>
                    </select>
                   </div>
                 </div>
              </div>
              <!-- <div class="form-group">
                <label for="su-password-confirm">Confirm password</label>
                <div class="password-toggle">
                  <input class="form-control" type="password" id="su-password-confirm" required>
                  <label class="password-toggle-btn">
                    <input class="custom-control-input" type="checkbox"><i class="czi-eye password-toggle-indicator"></i><span class="sr-only">Show password</span>
                  </label>
                </div>
              </div> -->
              <button class="btn btn-primary btn-block btn-shadow" data-dismiss="modal" :disabled="!valid"
          @click="submit">Sign up</button>
          </form>
         <!-- <button class="btn btn-primary"  >Register Now</button> -->
        </div>
        <div class="col-md-6 mt-5 mt-md-0">
          <div class="card border-0 box-shadow">
            <div class="card-body">
              <h2 class="h4 mb-1">Already have account? Sign in</h2>
              <!-- <div class="py-3"> -->
                <!-- <h3 class="d-inline-block align-middle font-size-base font-weight-semibold mb-2 mr-2">With social account:</h3>
                <div class="d-inline-block align-middle"> -->
                  <!-- <a class="social-btn sb-google mr-2 mb-2" href="#" data-toggle="tooltip" title="Sign in with Google"><i class="czi-google"></i></a> -->
                  <!-- <a class="social-btn sb-facebook mr-2 mb-2" href="https://www.instagram.com/batumuliashop/" data-toggle="tooltip" title="Sign in with Facebook"><i class="czi-instagram"></i></a> -->
                  <!-- <a class="social-btn sb-twitter mr-2 mb-2" href="#" data-toggle="tooltip" title="Sign in with Twitter"><i class="czi-twitter"></i></a> -->
                  <!-- </div> -->
              <!-- </div> -->
              <hr>
              <!-- <h3 class="font-size-base pt-4 pb-2">Or using form below</h3> -->
              <p class="font-size-sm text-muted mb-4">Login to your account for make an order.</p>
               <router-link to="/account/sign-in"><button class="btn btn-primary" >Login Now</button></router-link>
            </div>
          </div>
        </div>

      </div>
    </div>
   
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'AccountRegister',
   data () {
        return {
            valid: true,
            name: '',
            nameRules: [
            v => !!v || 'Name is required',
            v => (v && v.length <= 255) || 'Name must be less than 255 characters'
            ],
            email: '',
            emailRules: [
            v => !!v || 'E-mail is required',
            v => /([a-zA-Z0-9_]{1,})(@)([a-zA-Z0-9_]{2,}).([a-zA-Z0-9_]{2,})+/.test(v) || 'E-mail must be valid'
            ],
            showPassword: false,
            password: '',
            passwordRules: [
            v => !!v || 'Password required.',
            v => (v && v.length >= 6) || 'Min 6 characters',
            ],
            phone: '',
            gender:'',
            birthdate:'',
            anniv_date:'',
            checkbox: false,
            address_line1: '',
            address_line2: '',
            address_line3: '',
            province_id: 0,
            city_id: 0,
        }
    },
    computed: {
      ...mapGetters({
            countCart : 'cart/count',
            guest : 'auth/guest',
            user : 'auth/user',
            dialogStatus  : 'dialog/status',
            currentComponent: 'dialog/component',
            prevUrl : 'prevUrl', 
             provinces     : 'region/provinces',
            cities        : 'region/cities',
      }),

      citiesByProvince(){
        let province_id = this.province_id
        return this.cities.filter((city) => {           
            if (city.province_id==province_id) return city
        })
      },
    },
    methods: {
          ...mapActions({
          setDialogStatus     : 'dialog/setStatus',
          setDialogComponent  : 'dialog/setComponent',
          setAlert  : 'alert/set',
          setAuth   : 'auth/set',
          setProvinces      : 'region/setProvinces',
          setCities         : 'region/setCities',
        }),
        submit () {
            // if (this.$refs.form.validate()) {
                let formData = new FormData()
                formData.set('name', this.name)
                formData.set('email', this.email)
                formData.set('phone', this.phone)
                formData.set('gender', this.gender)
                formData.set('birthdate', this.birthdate)
                formData.set('anniv_date', this.anniv_date)
                formData.set('password', this.password)
                formData.set('address_line1', this.address_line1)
                formData.set('address_line2', this.address_line2)
                formData.set('address_line3', this.address_line3)
                formData.set('province_id', this.province_id)
                formData.set('city_id', this.city_id)
                this.axios.post('/register', formData)
                    .then((response) => {
                        let { data } = response.data
                        this.setAuth(data)
                        this.setAlert({
                            status : true,
                            color  : 'success',
                            text  : 'Register success',
                        })
                        this.$router.push('/account/profile')
                    })
                .catch((error) => {
                    let { data } = error.response
                    this.setAlert({
                        status : true,
                        color  : 'danger',
                        text  : data.message,
                    })
                })
            // }
        },
        showPass(){
          this.showPassword = !this.showPassword;
        },


        
        close() {
            this.$emit('closed', false)
        }
    },
     created(){


        if(this.provinces && this.provinces.length==0){
            this.axios.get('/provinces')
            .then((response) => {
                let { data } = response.data
                this.setProvinces(data)
                
            })

            this.axios.get('/cities')
            .then((response) => {
                let { data } = response.data
                this.setCities(data)
            })
        }
    }

}
</script>