<template>
  <!-- Product carousel (You may also like)-->
  <div class="container py-5 my-md-3">
   

    

      <section class="d-none d-sm-block">
         <h2 class="h3 text-center pb-4">You may also like</h2>

        <div v-if="products.length > 0">
      <carousel 
      :autoplay="true"
      :nav="false"
      :dots="false"
      :items="4" >
        
          <!-- Product-->
                <div class="item card" style="width: 18rem;border:none;" v-for="itemProduct in products" v-bind:key="itemProduct.id" >
                  <div class="card products product-card">
                  <small>CODE: {{ itemProduct.kode }}</small>
                  <AddToWishlist :itemProduct="itemProduct" />
                  <a :href="'/product/detail/'+itemProduct.id+'/'+itemProduct.slug"  class="card-img-top d-block overflow-hidden"  >
                  <div class="p-0 w-100 d-flex align-items-center" style="height:200px; background:#ccc;">
                    <!-- <img v-bind:src="itemProduct.galleries[0].photo" alt="Product" class="img-fluid mx-auto" style="float:none" > -->
                     <img :src="'/small/thumb_' + itemProduct.galleries[0].photo" alt="Product" class="img-fluid mx-auto" style="float:none" >
                  </div>
                  </a> 
                   
                  <div class="card-body py-2 px-0">
                    <h4 class="product-title "><router-link v-bind:to="'/product/detail/'+itemProduct.id+'/'+itemProduct.slug" ><b>{{ itemProduct.name }}</b></router-link><br>
                    <small>
                      <strong>{{ itemProduct.carats }}</strong> <br>
                      {{ itemProduct.dimensions }}
                    </small>
                    
                    <div class="d-flex justify-content-between mt-0">
                      <div class="product-price">
                        <!-- <span class="text-accent font-size-lg">Rp {{ itemProduct.price.toLocaleString('id-ID') }}</span> -->
                         <div v-if="itemProduct.discount_status == 1" class="font-size-lg text-accent pt-2" > 
                            Rp {{ itemProduct.discount_price.toLocaleString('id-ID') }} <br>
                          
                            <span class="font-size-xs p-1 mr-2" style="background-color:red; color:#fff; border-radius:3px;"><strong>{{ itemProduct.discount_persen }}%</strong></span> 
                            <span class="font-size-sm" style="text-decoration: line-through;color:gray;">Rp {{ itemProduct.price.toLocaleString('id-ID') }}</span>
                        </div>
                        <div v-else class="font-size-lg text-accent pt-2">Rp {{ itemProduct.price.toLocaleString('id-ID') }}</div> 
                        
                        
                      </div>
                      <!-- <div class="star-rating"><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star"></i></div> -->
                    </div>
                    </h4>
                  </div>
                   </div>
                </div>

             
         
             
           
          </carousel>
          </div>
      </section>

    
 
  </div>
</template>

<script type="module">
import carousel from 'vue-owl-carousel'
import { mapGetters, mapActions } from 'vuex'
import AddToWishlist from '@/components/AddToWishlist.vue'

// import axios from "axios";

export default {
  name: 'ProductYouMayLike',
  components: {
    carousel,
    AddToWishlist,
 
  },
  data() {
      return {
        products: [],
        base_url: process.env.VUE_APP_API_URL,
      }
  }, 
  mounted() {
        // let url = '/products-flashsale'
        this.axios.get('/products-newarrival')
        .then(res => (this.products = res.data.data.data))
        //  eslint-disable-next-line no-console
        .catch(err => console.log(err));
  },
    methods: {
            ...mapActions({
          setAlert  : 'alert/set',
      }),



    
 
      
    },
    computed: {
         ...mapGetters({
          user    : 'auth/user',
          }),
    }

}


</script>
