<template>
<div>
    <PreloaderWishlistBig v-if="iswishlist" :iswishlist="iswishlist" />
    <PreloaderUnwishBig v-else   :iswishlist="iswishlist" />
  <span class="mt-3 d-none d-sm-block" style="position:absolute; z-index:1; right:20px;"> 
            
    <button class="btn-wishlist " type="button" data-toggle="tooltip" data-placement="left" title="Add to wishlist" @click="savewishlist(productDetails.id)" style="width:50px; height:50px;">
      <i class="fa fa-heart mt-2" :class="isInclude(productDetails.id)?'wishlist-active': ''" aria-hidden="true" style="font-size:32px;"></i> 
      </button>
  </span>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import PreloaderWishlistBig from './preloader/PreloaderWishlistBig.vue'
import PreloaderUnwishBig from './preloader/PreloaderUnwishBig.vue'
import $ from 'jquery'


export default {
  name: 'AddToWishlistDetailGbr',
  props: ['productDetails'],
  data(){
    return {
      gifURL: '/img/preloader/heart_like_besar_00000.gif',
      iswishlist:null
    }
  },
  components:{
    PreloaderWishlistBig,
    PreloaderUnwishBig 
  },
  computed: {
      ...mapGetters({
          countCart : 'cart/count',
          wishlists  : 'wishlist/wishlists',
          guest : 'auth/guest',
          user : 'auth/user',
          dialogStatus  : 'dialog/status',
          currentComponent: 'dialog/component',
          prevUrl : 'prevUrl', 
      }), 
      anotherArrayName() {
          return this.wishlists.map(item => item.products_id)
      },
  },

    methods: {
    
      

      ...mapActions({
      setDialogStatus     : 'dialog/setStatus',
      setDialogComponent  : 'dialog/setComponent',
      setAuth             : 'auth/set',
      setAlert            : 'alert/set',
      setWishlists        : 'wishlist/setWishlists',
    }),
     isInclude(products_id) {
        return this.anotherArrayName.includes(products_id)
    },
         openModal () {
      $('#signin-modal').modal('show')
    },
      
     savewishlist(products_id){
      if(!this.isInclude(this.productDetails.id)){
        this.iswishlist = true

      }else{
         this.iswishlist = false
        
      }
        let config = {
          headers: {
            'Authorization': 'Bearer ' + this.user.api_token,
          },
        }

        let formData = {
            'products_id' : products_id,
        }

        this.axios.post('/make-wishlist', formData, config)
          .then((response) => {
            this.info = response.data.message
            this.color = response.data.status
          let {data } = response.data
          this.items = data
            this.setAlert({
                status : true,
                color  : this.color,
                text  : this.info,
              })
           
            let config = {
                            headers: {
                            'Authorization': 'Bearer ' + this.user.api_token,
                            },
                        }
                        this.axios.get('/my-wishlist', config)
                         .then((response) => {
                            let { data } = response.data
                            this.setWishlists(data)
                           
                            // this.onGifLoad()
                        //     this.setAlert({
                        //     status : true,
                        //     color  : 'success',
                        //     text  : 'wishlist data',
                        // })
                         })

          })
          .catch(() => {
            // let {data} = error.response   
            // this.setAlert({
            //     status : true,
            //     color  : 'danger',
            //     text  : data.message,
            // })
             this.openModal()
          })
      },




    },


    
   
};

</script>

<style scoped>
.wishlist-active {
  color:red;
}
</style>