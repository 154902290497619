<template>
  <div class="home container-fluid px-0">
     
    <HeroSlider />

<div class="container">

      <div class="row">

     <!-- Sidebar-->
        <aside class="col-lg-3">
        <!-- <aside class="col-lg-4 d-block d-sm-none"> -->
          <!-- Sidebar-->
          <div class="cz-sidebar rounded-lg box-shadow-lg" id="shop-sidebar">
            <div class="cz-sidebar-header box-shadow-sm">
              <button class="close ml-auto" type="button"  @click="closeSidenavbar" data-dismiss="sidebar" aria-label="Close"><span class="d-inline-block font-size-xs font-weight-normal align-middle">Close sidebar</span><span class="d-inline-block align-middle ml-2" aria-hidden="true">&times;</span></button>
            </div>
            <div class="cz-sidebar-body">           
              <!-- CATEGORIES -->
              <Category />
            </div>
          </div>
        </aside> 
    <div class="col-lg-9">

<!-- <div class="row d-flex justify-content-center">
  <div class="col text-center">
    <h2 class="h3">Also Available At</h2>
              <a href="https://www.tokopedia.com/batumuliashop" target="_blank"><img src="/img/footer/mp1.png" class="mr-3" alt="tokopedia"></a>
              <a href="https://www.bukalapak.com/u/batumuliashop" target="_blank"><img src="/img/footer/mp2.png" class="mr-3" alt="bukalapak"></a>
              <a href="https://www.instagram.com/batumuliashop" target="_blank"><img src="/img/footer/mp3.png" class="mr-3" alt="instagram"></a>
  </div>
</div> -->



    <ProductRecommendation />
    <ProductDiscount />

    <!-- <div class="row d-flex justify-content-center">
      <div class="col-12 col-md-6">
        <div class="btn-group btn-group-sm d-flex justify-content-center" role="group" aria-label="Basic example" >
                          <a :href="'http://wa.me/628176781986?text=Halo, saya ingin tau lebih detail produk:%0A%0A' + urlwindow  + this.$route.path" target="_blank" role="button"  class="btn " style="background-color: #E9EDF6; color:#78367f"><i class="fa fa-whatsapp"></i> Whatsapp</a>
                           <a href="tel:+62218354050" role="button" class="btn border-left " style="background-color: #E9EDF6; color:#78367f;"><i class="fa fa-phone"></i> Call Us</a>
                        </div> -->
      <!-- </div>
    </div> -->

   

    <ProductNewArrival />
    <!-- <Banners /> -->
    <ProductBestSeller />
    <!-- <BlogPost /> -->

    <Testimonial />
        <LogoPartner />
      </div>

      </div>

      </div>

 
      

    


  </div> 
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex'
import HeroSlider from '@/components/HeroSlider.vue'
import ProductRecommendation from '@/components/ProductRecommendation.vue'
import ProductDiscount from '@/components/ProductDiscount.vue'
import ProductNewArrival from '@/components/ProductNewArrival.vue'
// import Banners from '@/components/Banners.vue'
import ProductBestSeller from '@/components/ProductBestSeller.vue'
// import BlogPost from '@/components/BlogPost.vue'
import LogoPartner from '@/components/LogoPartner.vue'
import Category from '@/components/Category.vue'
import Testimonial from '../components/Testimonial.vue'



export default {
  name: 'Home',
  components: {
    HeroSlider,
    ProductRecommendation,
    ProductDiscount,
    ProductNewArrival,
    // Banners,
    ProductBestSeller,
    // BlogPost,
    LogoPartner,
    Category,
    Testimonial
},

   metaInfo() {
        return { 
            title: "Batumuliashop.com",
            meta: [
                { name: 'description', content:  'Pusat batumulia terbesar, terlengkap dan terpercaya'},
                { property: 'og:title', content: 'Batumuliashop.com'},
                { property: 'og:site_name', content: 'Batumuliashop.com'},
                {property: 'og:type', content: 'website'},    
                {name: 'robots', content: 'index,follow'} 
            ]
        }
    },

      computed: {
    ...mapGetters({
        user          : 'auth/user',
        provinces     : 'region/provinces',
        cities        : 'region/cities',
        // tambahkan ini
        carts         : 'cart/carts',
        countCart     : 'cart/count',
        totalPrice    : 'cart/totalPrice',
        totalSales    : 'cart/totalSales',
        totalDiscount : 'cart/totalDiscount',
        totalFreeOngkir : 'cart/totalFreeOngkir',
        totalQuantity : 'cart/totalQuantity',
        totalWeight   : 'cart/totalWeight',
        guest         : 'auth/guest',
        dialogStatus  : 'dialog/status',
        currentComponent: 'dialog/component',
        prevUrl       : 'prevUrl', 
    }),
  },

   methods: {
          ...mapActions({
        setAlert      : 'alert/set',
        setAuth       : 'auth/set',
        setProvinces  : 'region/setProvinces',
        setCities     : 'region/setCities',
        setCart       : 'cart/set',
        setPayment    : 'setPayment'
      }),

        closeSidenavbar() {
        document.querySelector(".cz-sidebar").classList.remove("show");
        document.querySelector(".toolbar-enabled").classList.remove("offcanvas-open");
    },
   },
    mounted() {
        window.scrollTo(0, -1);
    }


}

</script>



<style scoped>

</style>
