<template>

<div id="app">
<Preloader color="red" scale="0.6" :isloaded="isloaded" />
<div class="headerbms">
      <!-- Sign in / sign up modal-->
    <div class="modal fade" ref="modal" id="signin-modal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <ul class="nav nav-tabs card-header-tabs" role="tablist">
              <li class="nav-item"><a class="nav-link active" href="#signin-tab" data-toggle="tab" role="tab" aria-selected="true"><i class="czi-unlocked mr-2 mt-n1"></i>Sign in</a></li>
              <li class="nav-item"><a class="nav-link" href="#signup-tab" data-toggle="tab" role="tab" aria-selected="false"><i class="czi-user mr-2 mt-n1"></i>Sign up</a></li>
            </ul>
            <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          </div>
          <div class="modal-body tab-content py-4">
             <!-- Login -->
            <Login />
            <Register />
          </div>
        </div>
      </div>
    </div>
    <!-- End Sign in / sign up modal -->
    <!-- <ModalSigninSignup /> -->
  <FormAddPaymentMethod />

     <!-- Navbar 3 Level (Light)-->
    <header class="box-shadow-sm">
      <!-- Topbar-->
      <div class="topbar topbar-dark bg-dark">
        <div class="container">
          <div class="topbar-text dropdown d-md-none"><a class="topbar-link dropdown-toggle" href="#" data-toggle="dropdown">Useful links</a>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" href="tel:0218354050"><i class="czi-support text-muted mr-2"></i>(021) 8354050</a></li>
              <!-- <li><router-link class="dropdown-item" to="/order-tracking" ><i class="czi-location text-muted mr-2"></i>Order tracking</router-link></li> -->
            </ul>
          </div>
          <div class="topbar-text text-nowrap d-none d-md-inline-block"><i class="czi-support"></i><span class="text-muted mr-1">Support</span><a class="topbar-link" href="tel:0218354050">(021) 8354050</a></div>
          <div class="cz-carousel cz-controls-static d-none d-md-block">
          </div>
          <div class="ml-3 text-nowrap">
            <!-- <router-link class="topbar-link mr-4 d-none d-md-inline-block" to="/order-tracking" ><i class="czi-location"></i>Order tracking</router-link> -->
          </div>
        </div>
      </div>
      <!-- Remove "navbar-sticky" class to make navigation bar scrollable with the page.-->
      <div class="navbar-sticky bg-light">
        <div class="navbar navbar-expand-lg navbar-light">
          <div class="container"><a class="navbar-brand d-none d-sm-block mr-3 flex-shrink-0" href="\" style="min-width: 7rem;"><img width="250" src="/img/logo_batumuliashop.png" alt="Logo Batu Mulia Shop"/></a><a class="navbar-brand d-sm-none mr-2" href="\" style="min-width: 4.625rem;"><img width="150" src="/img/logo_batumuliashop.png" alt="Logo Batu Mulia Shop"/></a>
          <div class="mx-4 w-100 d-none d-lg-flex">
            <Search />
          </div>
          
            <!-- <div class="input-group-overlay d-none d-lg-flex mx-4">
              <input class="form-control appended-form-control" type="text" placeholder="Search for products2" >
              <div class="input-group-append-overlay"><span class="input-group-text"><i class="czi-search"></i></span></div>
            </div> -->
            <div class="navbar-toolbar d-flex flex-shrink-0 align-items-center">
              <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"><span class="navbar-toggler-icon"></span></button><a class="navbar-tool navbar-stuck-toggler" href="#"><span class="navbar-tool-tooltip">Expand menu</span>
                <div class="navbar-tool-icon-box"><i class="navbar-tool-icon czi-menu"></i></div></a>
                <!-- <router-link class="navbar-tool d-none d-lg-flex" to="/account/wishlist"><span class="navbar-tool-tooltip">Wishlist</span>
                <div class="navbar-tool-icon-box"><i class="navbar-tool-icon czi-heart"></i></div></router-link> -->

                <a v-if="guest" class="navbar-tool ml-1 ml-lg-0 mr-n1 mr-lg-2" href="#signin-modal" data-toggle="modal">
                <div class="navbar-tool-icon-box"><i class="navbar-tool-icon czi-user"></i></div>

                <div class="navbar-tool-text ml-n3">
                  <small >Hello, <span>Sign in</span></small>
                </div>
                </a>

                <router-link v-if="!guest" class="navbar-tool ml-1 ml-lg-0 mr-n1 mr-lg-2" to="/account/profile" data-toggle="modal">
                <div class="navbar-tool-icon-box"><i class="navbar-tool-icon czi-user"></i></div>
                <div class="navbar-tool-text ml-n3">
                   <small >Hello, <span>{{ user.name }}</span></small>
                  <span>MyAccount</span></div>
                  </router-link>
                   <!-- <br><button v-if="!guest" @click="logout">Logout</button> -->

                <div class="navbar-tool dropdown ml-3">
                  <router-link class="navbar-tool-icon-box bg-secondary dropdown-toggle" to="/cart">
                  <span class="navbar-tool-label" v-if="countCart>0">{{ countCart }}</span><i class="navbar-tool-icon czi-cart"></i>
                  </router-link>

                  <CartDropdown />
                </div>
              
            </div>
                
          </div>
        </div>
        <div class="navbar navbar-expand-lg navbar-light navbar-stuck-menu mt-n2 pt-0 pb-2">
          <div class="container">
            <div class="primarymenu collapse navbar-collapse" id="navbarCollapse" >
              <!-- Search-->
              <div class="w-100 d-block d-sm-none ">
                <Search />
              </div>
               
              <!-- <div class="input-group-overlay d-lg-none my-3">
                <div class="input-group-prepend-overlay"><span class="input-group-text"><i class="czi-search"></i></span></div>
                <input class="form-control prepended-form-control" type="text" placeholder="Search for products">
              </div> -->
             
              <!-- Primary menu-->
              <ul class="navbar-nav">
                <li class="nav-item dropdown active" @click="primarymenu"><router-link class="nav-link" to="/">Home</router-link>
                </li>
                <!-- <li class="nav-item"><router-link class="nav-link" to="/informasi">About</router-link>
                </li> -->
                 <li class="nav-item dropdown" @click="primarymenu" ><router-link class="nav-link" to="/gemstones"  >Gemstones</router-link>
                </li>
                 <li class="nav-item dropdown" @click="primarymenu" ><router-link class="nav-link" to="/crystal"  >Crystal</router-link>
                </li>
                 <li class="nav-item dropdown" @click="primarymenu" ><router-link class="nav-link" to="/diamonds">Diamonds</router-link>
                </li>
                <li class="nav-item dropdown" @click="primarymenu"><router-link class="nav-link" to="/jewelry">Jewelry</router-link>
                </li>
                <li class="nav-item dropdown" @click="primarymenu"><router-link class="nav-link" to="/custom-jewelry">Custom Bespoke Jewelry</router-link>
                </li>

                <li class="nav-item dropdown" @click="primarymenu"><router-link class="nav-link" to="/birthstones">Birthstones</router-link>
                </li>

                <li class="nav-item" @click="primarymenu"><router-link class="nav-link" to="/informasi">Information</router-link>
                </li>
                <li class="nav-item" @click="primarymenu"><router-link class="nav-link" to="/contacts">Contact</router-link>
                </li>
              </ul>

            </div>
          </div>
        </div>
      </div>
    </header>

</div>

  <router-view/>

  


      <!-- Footer-->
    <footer class="pt-5" style="background-color:rgba(204,204,204,0.5);">
      <div class="container">
        <div class="row pb-2">
          <div class="col-md-3 col-sm-6">
            <div class="widget widget-links widget-light pb-2 mb-4">
             
              <div class="d-flex flex-wrap">
                <div class="mr-2 mb-2">
                  <address>
                    <h5>Batumuliashop.com</h5>
                    
                    <small>
                    <p><b>Suite 16A, Menara Imperium</b><br>
                    Metropolitan Kuningan Super Blok,<br>Kavling No.1<br>
                    Jl. HR Rasuna Said Jakarta 12980 - Indonesia</p>
                    
                    <p>Telp. 021-8354050 Fax. 021-8354037<br>
                    Email : Contact@batumuliashop.com</p>
    
                    <h6>Office Hour</h6>
                    Senin-Jumat (10.00 - 16.00 WIB)<br>
                    Sabtu (by appointment only)
                    </small>
                    </address>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="widget pb-2 mb-4">
              <h3 class="widget-title text-dark pb-1">Also Available At</h3>
              <a href="https://www.tokopedia.com/batumuliashop" target="_blank"><img src="/img/footer/mp1.png" class="mr-3" alt="tokopedia"></a>
              <a href="https://www.bukalapak.com/u/batumuliashop" target="_blank"><img src="/img/footer/mp2.png" class="mr-3" alt="bukalapak"></a>
              <a href="https://www.instagram.com/batumuliashop" target="_blank"><img src="/img/footer/mp3.png" class="mr-3" alt="instagram"></a>
              <div class="row mt-4">
                  <div class="col ">
                    <a href="tel:+62218354050">
                      <img src="/img/footer/btn_contact1.png" alt="call batumuliashop.com">
                    </a>
                  </div>
              </div>
              <div class="row mt-4">
                  <div class="col">
                    <a href="http://wa.me/628176781986" target="_blank">
                      <img src="/img/footer/btn_contact2.png" alt="whatsapp batumuliashop.com">
                    </a>
                  </div>
              </div>
            </div>
            
          </div>

          <div class="col-md-3 col-sm-6">
            <!-- <div class="widget widget-links widget-dark pb-2 mb-4">
              <h3 class="widget-title text-dark">About us</h3>
              <ul class="widget-list">
                <li class="widget-list-item"><a class="widget-list-link" href="#">About company</a></li>
              </ul>
            </div> -->
            <div class="widget pb-2 mb-4">
              <h3 class="widget-title text-dark pb-1">Metode Pembayaran</h3>
              <div class="d-flex flex-wrap">
                <div class="mr-2 mb-2"><img src="/img/footer/bank1.png" alt="bca"></div>
              </div>
            </div>
          </div> 
          <div class="col-md-3 col-sm-6">
            <!-- <div class="widget widget-links widget-dark pb-2 mb-4">
              <h3 class="widget-title text-dark">Account &amp; shipping info</h3>
              <ul class="widget-list">
                <li class="widget-list-item"><a class="widget-list-link" href="#">Your account</a></li>
                <li class="widget-list-item"><a class="widget-list-link" href="#">Order tracking</a></li>
                <li class="widget-list-item"><a class="widget-list-link" href="#">Delivery info</a></li>
              </ul>
            </div> -->
            <div class="widget pb-2 mb-4">
              <h3 class="widget-title text-dark pb-1">Jasa Pengiriman</h3>
              <div class="d-flex flex-wrap">
                <div class="mr-2 mb-2"><a href="https://www.jne.co.id/index.php/id/tracking/trace"><img src="/img/footer/bank2.png" alt="jne"></a></div>
              </div>
            </div>
          </div>
          
        </div>
      </div>

      <div style="background-color:#642369; color:#FFF;">
        <div class="container">
          <div class="py-2 font-size-sm opacity-50 text-center">&copy; {{ new Date().getFullYear() }} Batumuliashop.com All rights reserved.</div>
        </div>
      </div>
    </footer>
    <!-- Toolbar for handheld devices-->
    <div class="cz-handheld-toolbar">
      <div class="d-table table-fixed w-100">
        <!-- <a v-if="isHome || isGemstones || isSubGemstones || isJewelry || isSubjewelry"  class="d-table-cell cz-handheld-toolbar-item" href="#" @click="sidenavbar" data-toggle="sidebar" onclick="window.scrollTo(0, 0)"><span class="cz-handheld-toolbar-icon"><i class="czi-filter-alt"></i></span><span class="cz-handheld-toolbar-label">Filters</span></a> -->
        <a  class="d-table-cell cz-handheld-toolbar-item" href="#" @click="sidenavbar" data-toggle="sidebar" onclick="window.scrollTo(0, 0)"><span class="cz-handheld-toolbar-icon"><i class="czi-filter-alt"></i></span><span class="cz-handheld-toolbar-label">Filters</span></a>


        <router-link v-if="!guest" class="d-table-cell cz-handheld-toolbar-item"  to="/account/wishlist"><span class="cz-handheld-toolbar-icon"><i class="czi-heart"></i></span><span class="cz-handheld-toolbar-label">Wishlist</span></router-link>
        <a class="d-table-cell cz-handheld-toolbar-item" href="#navbarCollapse" data-toggle="collapse" onclick="window.scrollTo(0, 0)"><span class="cz-handheld-toolbar-icon"><i class="czi-menu"></i></span><span class="cz-handheld-toolbar-label">Menu</span></a>

          <!-- <span class="navbar-tool-label" v-if="countCart>0">{{ countCart }}</span><i class="navbar-tool-icon czi-cart"></i> -->
        
        <router-link class="d-table-cell cz-handheld-toolbar-item" to="/cart"><span class="cz-handheld-toolbar-icon"><i class="czi-cart"></i><span class="badge badge-primary badge-pill py-1" v-if="countCart>0">
          {{ countCart }}
          </span></span><span class="cz-handheld-toolbar-label">Cart</span></router-link>
      </div>
    </div>
    <!-- Back To Top Button--><a class="btn-scroll-top" href="#top" data-scroll><span class="btn-scroll-top-tooltip text-muted font-size-sm mr-2">Top</span><i class="btn-scroll-top-icon czi-arrow-up">   </i></a>
    <!-- Vendor scrits: js libraries and plugins-->
    <!-- <script src="vendor/jquery/dist/jquery.slim.min.js"></script>
    <script src="vendor/bootstrap/dist/js/bootstrap.bundle.min.js"></script>
    <script src="vendor/bs-custom-file-input/dist/bs-custom-file-input.min.js"></script>
    <script src="vendor/simplebar/dist/simplebar.min.js"></script>
    <script src="vendor/tiny-slider/dist/min/tiny-slider.js"></script>
    <script src="vendor/smooth-scroll/dist/smooth-scroll.polyfills.min.js"></script>
    <script src="vendor/drift-zoom/dist/Drift.min.js"></script> -->
    <!-- Main theme script-->
    <!-- <script src="js/theme.min.js"></script> -->
  
 <Alert /> 
          <AccountProfileAvatar />
          <FilterSidebar />
   
<!-- <FooterBms /> -->

</div>
</template>


<script>
import 'jquery/dist/jquery.slim.min.js'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import 'bs-custom-file-input/dist/bs-custom-file-input.min.js'
import 'simplebar/dist/simplebar.css';
import 'tiny-slider/dist/min/tiny-slider.js'
import 'smooth-scroll/dist/smooth-scroll.polyfills.min.js'
import 'drift-zoom/dist/Drift.min.js'

// import FooterBms from '@/components/FooterBms.vue'
import FormAddPaymentMethod from '@/components/FormAddPaymentMethod.vue'
import CartDropdown from '@/components/CartDropdown.vue'
import { mapActions, mapGetters } from 'vuex'
import Search from './components/Search.vue'
import AccountProfileAvatar from '@/components/AccountProfileAvatar.vue'
// import PreloaderPage from '@/components/preloader/PreloaderPage.vue'
import Preloader from '@/components/Preloader.vue'
import FilterSidebar from '@/components/FilterSidebar.vue'




export default {
  name: 'Home',
  data (){
    return {
      base_url: process.env.VUE_APP_API_URL,
    }
  },
  components: {
        FormAddPaymentMethod,
        CartDropdown,
        Alert: () => import( /* webpackChunkName: "alert" */'@/components/Alert.vue'),
        Login: () => import( /* webpackChunkName: "login" */ '@/components/Login.vue'),
        Register: () => import( /* webpackChunkName: "register" */ '@/components/Register.vue'),
        // ModalSigninSignup: () => import( /* webpackChunkName: "ModalSigninSignup" */ '@/components/ModalSigninSignup.vue'),
        Search,
        AccountProfileAvatar,
        // PreloaderPage
        Preloader,
        FilterSidebar
       
 

  },

  created(){

    var scripts = [
      "https://s7.addthis.com/js/300/addthis_widget.js#pubid=ra-62cd01c1b20e72d9"
    ];
    scripts.forEach(script => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
  },

  methods: {
    ...mapActions({
      setDialogStatus     : 'dialog/setStatus',
      setDialogComponent  : 'dialog/setComponent',
      setAuth             : 'auth/set',
      setAlert            : 'alert/set',
      setWishlists        : 'wishlist/setWishlists',
    }), 
  
    sidenavbar() {

          document.querySelector(".cz-sidebar").classList.add("show");
          document.querySelector(".toolbar-enabled").classList.add("offcanvas-open");
        
        },
    primarymenu() {

           document.querySelector(".primarymenu").classList.remove("show");
        
        },
    closeSidenavbar() {
        document.querySelector(".cz-sidebar").classList.remove("show");
        document.querySelector(".toolbar-enabled").classList.remove("offcanvas-open");
    },
    logout(){
      let config = {
        headers: {
          'Authorization': 'Bearer ' + this.user.api_token,
        },
      }
      this.axios.post('/logout', {}, config)
        .then(() => {
          this.setAuth({}) // kosongkan auth ketika logout
           this.setWishlists([]) 
          this.setAlert({
              status : true,
              color  : 'success',
              text  : 'Logout successfully',
            })
          this.$router.push( { path: '/' })
        })
        .catch((error) => {
          let {data} = error.response   
          this.setAlert({
              status : true,
              color  : 'error',
              text  : data.message,
          })
        })
    }
  },
  computed: {
  isHome () {
    return (this.$route.path==='/')
    },
    isGemstones (){
        return (this.$route.path === '/gemstones')
    },
    isSubGemstones (){
        return this.$route.name === 'ShopSubcategory'
    },
    isSubjewelry (){
        return this.$route.name === 'ShopSubjewelry'
    },
     isJewelry (){
        return (this.$route.path === '/jewelry')
    },
    ...mapGetters({
      countCart : 'cart/count',
     wishlists  : 'wishlist/wishlists',
      guest : 'auth/guest',
      user : 'auth/user',
      dialogStatus  : 'dialog/status',
      currentComponent: 'dialog/component',
      prevUrl : 'prevUrl', 
    }), 
    dialog:{
      get () {
        return this.dialogStatus
      },
      set (value) {
        this.setDialogStatus(value)
      }
    }
  },

}
  


</script>

<style>

</style>
