<template>
  <div class="modal fade" ref="form" id="option-address">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">

        <div class="modal-header">
          <h3 class="modal-title"><strong>Choose Address</strong></h3>
          <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        </div>

        <div class="modal-body">
          <div v-if="addresses.length>0" class="table-responsive font-size-md">
            <div v-for="address in addresses" :key="address.id" class="row mx-4 my-2 p-3 " :class="address.is_default==1?'default-address':'other-address'"  style="border-radius:20px;">
              <div class="col-md-8">
                <div v-if="address.name"><strong>{{ address.name }} {{ address.lastname }}</strong> <span>({{address.alias}})</span> 
                    <span v-if="address.is_default" class="badge badge-success"><small>Primary Address</small></span></div>
                    <strong v-if="!address.name">{{ user.name }} <span v-if="address.is_default" class="badge badge-success"><small>Primary Address</small></span></strong> <br>
                    <span  v-if="address.phone">{{ address.phone }}</span>
                    <span  v-if="!address.phone">{{ user.phone }}</span><br>
                        
                    <span v-if="address.address_line1">{{ address.address_line1 }}<br></span>
                    <span v-if="address.address_line2">{{ address.address_line2 }}<br></span>
                    <span v-if="address.address_line3">{{ address.address_line3 }}<br></span>
                    <span v-for="city in cities" :key="city.id">
                    <span v-if="city.id == address.city_id" >{{ city.city }}</span>
                    </span><br>
                    <span v-for="province in provinces" :key="province.id">
                    <span v-if="province.id == address.province_id">{{ province.province }}</span>
                    </span><br>
                </div>
                <div class="col-md-4 d-flex align-items-center">
                    <button v-if="!address.is_default" class="btn btn-primary" @click="primaryAddress(address.id)"  data-dismiss="modal" ><strong>Pilih Alamat</strong></button>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>      
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'AddressOption',

  data(){
    return{   
      addresses:[],
      info: null,
      
      base_url: process.env.VUE_APP_API_URL,
    }
  },
  computed: {
    ...mapGetters({
        user          : 'auth/user',
        provinces     : 'region/provinces',
        cities        : 'region/cities',
        // tambahkan ini
        carts         : 'cart/carts',
        countCart     : 'cart/count',
        totalPrice    : 'cart/totalPrice',
        totalSales    : 'cart/totalSales',
        totalDiscount : 'cart/totalDiscount',
        totalFreeOngkir : 'cart/totalFreeOngkir',
        totalQuantity : 'cart/totalQuantity',
        totalWeight   : 'cart/totalWeight',
        guest         : 'auth/guest',
        dialogStatus  : 'dialog/status',
        currentComponent: 'dialog/component',
        prevUrl       : 'prevUrl', 
    }),
  },
  methods: {
    ...mapActions({
         setAlert      : 'alert/set',
        setAuth       : 'auth/set',
        setProvinces  : 'region/setProvinces',
        setCities     : 'region/setCities',
        setCart       : 'cart/set',
        setPayment    : 'setPayment'
      }),

          primaryAddress($address_id){
                let formData = new FormData()
                formData.set('address_id', $address_id)
                formData.set('is_default', 1)

                let config = {
                    headers: {
                        'Authorization': 'Bearer ' + this.user.api_token,
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    },
                }

                this.axios.post('/make-default-address/' + $address_id, formData, config)
                    .then(() => {

                        this.setAlert({
                            status  : true,
                            color   : 'success',
                            text    : 'Address Update Successfully',
                        })
                        this.addresslist()
                         this.passEvent()
                        this.close()
                    })
                    .catch((error) => {
                        let {data} = error.response   
                        this.setAlert({
                            status : true,
                            color  : 'danger',
                            text  : data.message,
                        })
                    })
                },
                close() {
                    this.$emit('closed', false)
                },
                clear () {
                    this.$refs.form.reset()
                },

                addresslist(){
                   let config = {
                      headers: {
                          'Authorization': 'Bearer ' + this.user.api_token,
                      },
                    }

                    this.axios.get('/my-address',config)
                      .then((response) => {
                        this.info = response.data.message
                        let {data } = response.data
                        this.addresses = data 
                        

                      })
                      .catch((error) => {
                        let {data} = error.response
                        this.setAlert({
                            status : true,
                            text  : data.message,
                            color  : 'danger',
                      }) })


                },

                passEvent()
                  {
                      let config = {
                        headers: {
                            'Authorization': 'Bearer ' + this.user.api_token,
                        },
                      }
                      this.axios.get('/default-address',config)
                      .then((response) => {
                            this.info = response.data.message
                            let {data} = response.data
                            this.addressDefault = data
                        this.$emit('chooseAddress', data)
                      })
                  }
                
    },
      

  mounted() {

    let config = {
      headers: {
          'Authorization': 'Bearer ' + this.user.api_token,
      },
    }
    

    this.axios.get('/my-address',config)
      .then((response) => {
        this.info = response.data.message
        let {data } = response.data
        this.addresses = data
      })
      .catch((error) => {
        let {data} = error.response
        this.setAlert({
            status : true,
            text  : data.message,
            color  : 'danger',
      }) })

},

}
</script>
<style scoped>
.default-address{
  border: 1px solid green;
}
.other-address{
  border: 1px solid #ccc;
}
</style>