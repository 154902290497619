<template>
        <!-- Best Seller-->
    <section class="container ">
      <h2 class="h3 text-center pb-4">Best Seller</h2>
      <div class="row">
        <div class="col-md-3 col-sm-4 col-6"><router-link class="d-block bg-white box-shadow-sm rounded-lg mb-grid-gutter img-fluid" to="/gemstones/11/sapphire"><img class="d-block mx-auto" src="img/home/bestseller/bestseller3.png" alt="Brand" width="100%" ></router-link></div>
        <div class="col-md-3 col-sm-4 col-6"><router-link class="d-block bg-white box-shadow-sm rounded-lg mb-grid-gutter img-fluid" to="/gemstones/22/spinel-"><img class="d-block mx-auto" src="img/home/bestseller/bestseller1.png" alt="Brand" width="100%" ></router-link></div>
        <div class="col-md-3 col-sm-4 col-6"><router-link class="d-block bg-white box-shadow-sm rounded-lg mb-grid-gutter img-fluid" to="/gemstones/24/garnet"><img class="d-block mx-auto" src="img/home/bestseller/garnet.png" alt="Garnet" width="100%" ></router-link></div>

        <div class="col-md-3 col-sm-4 col-6"><router-link class="d-block bg-white box-shadow-sm rounded-lg mb-grid-gutter img-fluid" to="/gemstones/21/tourmaline"><img class="d-block mx-auto" src="img/home/bestseller/tourmaline.png" alt="Tourmaline" width="100%" ></router-link></div>
      </div>
    </section>
</template>

<script>
export default {
    name: 'ProductBestSeller'
    
}
</script>