<template>
      <!-- Sidebar-->
        <aside class="col-lg-4 pt-4 pt-lg-0">
          <div class="cz-sidebar-static rounded-lg box-shadow-lg px-0 pb-0 mb-5 mb-lg-0">
            <div class="px-4 mb-4">
              <div class="media align-items-center">
                <div class="img-thumbnail rounded-circle position-relative" style="width: 6.375rem;">
                  <!-- <span class="badge badge-warning" data-toggle="tooltip" title="Reward points">384</span> -->
                  <img class="rounded-circle" v-if="user.avatar==null && user.gender=='M'" src="/img/team/01.jpg"  alt="avatar">
                  <img class="rounded-circle" v-if="user.avatar==null && user.gender=='F'" src="/img/team/02.jpg"  alt="avatar">
                  <img  class="rounded-circle" v-if="user.avatar" :src="base_url + '/storage/images/avatar/' + user.avatar"  alt="avatar" >
                  <!-- <img class="rounded-circle" src="/img/shop/account/avatar.jpg" alt="Susan Gardner"> -->
                  </div>
                <div class="media-body pl-3">
                  <h3 class="font-size-base mb-0"><strong>{{ this.user.name }} {{ this.user.lastname }}</strong></h3><span class="text-accent font-size-sm">{{ this.user.email }}</span>
                </div>
              </div>
            </div>

            <div class="bg-secondary px-4 py-3">
              <h3 class="font-size-sm mb-0 text-muted">Dashboard</h3>
            </div>

            <ul class="list-unstyled mb-0">
              <li class="border-bottom mb-0">
                <router-link class="nav-link-style d-flex align-items-center px-4 py-3" to="/account/orders"><i class="czi-bag opacity-60 mr-2"></i>Orders<span class="font-size-sm text-muted ml-auto"> </span></router-link>
              </li>

              <li class="border-bottom mb-0">
                <router-link class="nav-link-style d-flex align-items-center px-4 py-3 active" to="/account/wishlist"><i class="czi-heart opacity-60 mr-2"></i>Wishlist<span class="font-size-sm text-muted ml-auto"> </span></router-link>
              </li>

              <!-- <li class="mb-0">
                <router-link class="nav-link-style d-flex align-items-center px-4 py-3" to="/account/support-tickets"><i class="czi-help opacity-60 mr-2"></i>Support tickets<span class="font-size-sm text-muted ml-auto">1</span></router-link>
                </li> -->
            </ul>

            <div class="bg-secondary px-4 py-3">
              <h3 class="font-size-sm mb-0 text-muted">Account settings</h3>
            </div>

            <ul class="list-unstyled mb-0">
              <li class="border-bottom mb-0">
                <router-link class="nav-link-style d-flex align-items-center px-4 py-3" to="/account/profile"><i class="czi-user opacity-60 mr-2"></i>Profile info</router-link>
              </li>

              <li class="border-bottom mb-0">
                <router-link class="nav-link-style d-flex align-items-center px-4 py-3" to="/account/address"><i class="czi-location opacity-60 mr-2"></i>Addresses</router-link>
              </li>

              <!-- <li class="mb-0">
                <router-link class="nav-link-style d-flex align-items-center px-4 py-3" to="/account/payment"><i class="czi-card opacity-60 mr-2"></i>Payment methods</router-link>
              </li> -->

              <li class="d-lg-none border-top mb-0" >
                <span v-if="!guest"  @click="logout" class="nav-link-style d-flex align-items-center px-4 py-3" ><i class="czi-sign-out opacity-60 mr-2"></i>Sign out</span>
              </li>
            </ul>

          </div>
        </aside>
</template>
 
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'AccountSidebarMenu',
      props: ['base_url'],
  components: {

  },
  methods: { 
    ...mapActions({
      setDialogStatus   : 'dialog/setStatus',
      setDialogComponent   : 'dialog/setComponent',
      setAuth   : 'auth/set',
      setAlert   : 'alert/set',
    }),
    logout(){
        let config = {
          headers: {
            'Authorization': 'Bearer ' + this.user.api_token,
          },
        }
        this.axios.post('/logout', {}, config)
          .then(() => {
            this.setAuth({}) // kosongkan auth ketika logout
            this.setAlert({
                status : true,
                color  : 'success',
                text  : 'Logout successfully',
              })
            this.$router.push( { path: '/' })
          })
          .catch((error) => {
            let {data} = error.response   
            this.setAlert({
                status : true,
                color  : 'danger',
                text  : data.message,
            })
          })
      },
   },
  computed: {
    totalHarga() {
      return this.keranjangUser.reduce(function(items, data){
        return items + data.price;
      },0);
    },
    isHome () {
      return (this.$route.path==='/')
    },
    ...mapGetters({
      countCart : 'cart/count',
      guest : 'auth/guest',
      user : 'auth/user',
      dialogStatus  : 'dialog/status',
      currentComponent: 'dialog/component',
    }),

  },
}


</script>