<template>
<div class="accountprofile">
    <!-- Page Title-->
    <div class="page-title-overlap bg-dark pt-4">
      <div class="container d-lg-flex justify-content-between py-2 py-lg-3">
        <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
              <li class="breadcrumb-item"><a class="text-nowrap" href="#"><i class="czi-home"></i>Home</a></li>
              <li class="breadcrumb-item text-nowrap"><a href="#">Account</a>
              </li>
              <li class="breadcrumb-item text-nowrap active" aria-current="page">Profile info</li>
            </ol>
          </nav>
        </div>
        <div class="order-lg-1 pr-lg-4 text-center text-lg-left">
          <h1 class="h3 text-light mb-0">Profile info</h1>
        </div>
      </div>
    </div>
    <!-- Page Content-->
    <div class="container pb-5 mb-2 mb-md-3">
      <div class="row">

        <!-- Sidebar-->
        <AccountSidebarMenu  :base_url="base_url" />

        <!-- Content  -->
        <section class="col-lg-8">
          <!-- Toolbar-->
          <div class="d-none d-lg-flex justify-content-between align-items-center pt-lg-3 pb-4 pb-lg-5 mb-lg-3">
            <h6 class="font-size-base text-light mb-0">Update you profile details below:</h6>
            <!-- <button class="btn btn-primary btn-sm" v-if="!guest" @click="logout"><i class="czi-sign-out mr-2"></i>Sign out</button> -->
            <LogoutDesktop />
          </div>

          <AccountProfileForm :base_url="base_url" />
             <!-- <AccountProfileAvatar /> -->
          
        </section>
      </div>
    </div>
</div>
</template>

<script>
import AccountSidebarMenu from '@/components/AccountSidebarMenu.vue'
import { mapActions, mapGetters } from 'vuex'
import AccountProfileForm from '@/components/AccountProfileForm.vue'
import LogoutDesktop from '@/components/LogoutDesktop.vue'
// import AccountProfileAvatar from '@/components/AccountProfileAvatar.vue'

export default {
  name: 'AccountProfile',
  data(){
    return{
         base_url: process.env.VUE_APP_API_URL,
    }
  },
  components: {
    AccountSidebarMenu,
    AccountProfileForm,
    LogoutDesktop
    //  AccountProfileAvatar

  },
 
   methods: { 
      ...mapActions({
        setDialogStatus   : 'dialog/setStatus',
        setDialogComponent: 'dialog/setComponent',
        setAuth           : 'auth/set',
        setWishlists      : 'wishlist/setWishlists',
        setAlert          : 'alert/set',
        setProvinces      : 'region/setProvinces',
        setCities         : 'region/setCities',
      }),
      


    },
    
   computed: {
    ...mapGetters({
      provinces     : 'region/provinces',
      cities        : 'region/cities',
      countCart     : 'cart/count',
      guest         : 'auth/guest',
      user          : 'auth/user',
      wishlists  : 'wishlist/wishlists',
      dialogStatus  : 'dialog/status',
      currentComponent: 'dialog/component',
    }),

   },
  
    

}
</script>