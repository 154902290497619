<template>
    <div>

        <!-- Modal with tabs and forms -->
<div class="modal" tabindex="-1" role="dialog" id="avatar">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <ul class="nav nav-tabs card-header-tabs" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" href="#signin" data-bs-toggle="tab" role="tab" aria-selected="true">
              <i class="ci-unlocked me-2 mt-n1"></i>
             Change Avatar
            </a>
          </li>

        </ul>
        <button class="btn" data-toggle="modal" aria-label="Close" data-target="#avatar">
          <i class="czi-close"></i>
        </button>
      </div>
      <div class="modal-body tab-content py-4">
        <form class="tab-pane fade show active" id="change-avatar"  enctype="multipart/form-data">
          <div class="mb-3">

              <!-- File input -->

  <div class="form-group">             
  <!-- <label for="file-input" class="form-label">File</label> -->
  <input type="file" name="file" id="imageUpload" class="form-control"  ref="fileInput" accept="image/*" :maxFileSize="1000000" @change="onFileChange">
  </div>
  <div class="form-group">   
    <!-- <div id="imagePreview" :style="{ 'background-image':'url($(url))' }" ></div> -->
    <img v-if="gbr_url" :src="gbr_url" width="300px" height="auto" />
  </div>
            



                <!-- <div class="input-group">
                    <div class="custom-file">
                <input  type="file" style="display: none"
  ref="fileInput" accept="image/*" @change="onFilePicked"  class="form-control custom-file-input" id="inputGroupFile01" >
                <label class="custom-file-label" for="inputGroupFile01" aria-describedby="inputGroupFileAddon01">Choose file</label>

                </div>
                </div> -->
          </div>
  
          
          <!-- <button class="btn btn-primary d-block btn-shadow" type="submit">Upload</button> -->
        </form>
       
      </div>
    </div>
  </div>
  </div>

    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'AccountProfileAvatar',
   data () {
    return {
      imageData:null,
      // url: null,
      gbr_url: null,
      gbr_image: null
    }
  },
  methods: {
        ...mapActions({
        setDialogStatus   : 'dialog/setStatus',
        setDialogComponent: 'dialog/setComponent',
        setAuth           : 'auth/set',
        setAlert          : 'alert/set',
        setProvinces      : 'region/setProvinces',
        setCities         : 'region/setCities',
      }),

      onUpload(e){
        console.log(e)
        const file = e.files[0];
        console.log(file)
        this.url = URL.createObjectURL(file);
      },

      onFileChange(e) {
  
            const file = e.target.files[0]
            this.gbr_image = file
            this.gbr_url = URL.createObjectURL(file)
     

            // console.log(e)
            // const file = e.target.files[0];
            // this.url = URL.createObjectURL(file);
            var imagefile = document.querySelector('#imageUpload');
            // console.log(file)
            console.log(imagefile.files[0].name)
            // this.imageData = imagefile.files[0].name

            // const reader = new FileReader()

            // reader.readAsDataURL(this.gbr_url);
            //  reader.onload = e => {
            //     this.gbr_image = e.target.result
            //     console.log(this.gbr_image)
            // }

      let formData = new FormData();
      formData.append("avatar", file);
      // formData.append("avatar", this.gbr_url);
      // formData.append("avatar_name", this.imageData);
      // this.$store.dispatch("uploadImage", formData);

         let config = {
            headers: {
                'Content-type': 'multipart/form-data',
                'Authorization': 'Bearer ' + this.user.api_token,
            },
      }
      
   this.axios.post('/avatar', formData, config)
    .then((response) => {
            let { data } = response
            this.user.avatar = data.data.avatar
            this.setAlert({
                status : true,
                color  : 'success',
                text  : data.message,
                // text  : 'Update Successfully',
              })
          })
          .catch((error) => {
            let {data} = error.response   
            this.setAlert({
                status : true,
                color  : 'danger',
                text  : data.message,
            })
          })


    },
       saveAvatar(){
        let formData = new FormData()
        formData.set('avatar', this.file)

         let config = {
                headers: {
                 
                    // 'Access-Control-Allow-Origin': '*',
                     'Content-type': 'multipart/form-data',
                    // 'Access-Control-Allow-Methods': 'GET, POST',
                    // 'Access-Control-Allow-Headers': 'X-Requested-With',
                    'Authorization': 'Bearer ' + this.user.api_token,
                    // 'Set-Cookie' : 'cross-site-cookie=whatever, flavor=choco, SameSite=None, Secure',
                },
                }

            this.axios.post('/avatar', formData, config)
              .then((response) => {
            let { data } = response.data
            this.setAuth(data)
            this.setAlert({
                status : true,
                color  : 'success',
                text  : data.message,
                // text  : 'Update Successfully',
              })
          })
          .catch((error) => {
            let {data} = error.response   
            this.setAlert({
                status : true,
                color  : 'danger',
                text  : data.message,
            })
          })

       },
      close() {
            this.$emit('closed', false)
        }
  },

  computed: {
    ...mapGetters({
      provinces     : 'region/provinces',
      cities        : 'region/cities',
      countCart     : 'cart/count',
      guest         : 'auth/guest',
      user          : 'auth/user',
      dialogStatus  : 'dialog/status',
      currentComponent: 'dialog/component',
    }),

   },
  
        
 
}
</script>
