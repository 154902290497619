<template>
    <!-- Discounted products (Carousel)-->
    <!-- <section class="container-fluid flashsale" > -->
    <section v-if="products.length > 0" class="container-fluid px-0" style="background:rgba(0,0,0,0.05);border-radius: 10px;" >
    <!-- <div  class="container pt-md-3 pb-3 mb-md-3" v-if="products.length > 0 || products.discount_status > 0 || products.quantity > 0"> -->
    <div  class="container pt-md-3 pb-3 mb-md-3" >
      <!-- Heading-->
      <div class="d-flex flex-wrap justify-content-between align-items-center pt-1" >
        <div class="pt-3 d-flex justify-content-start" >
          <h2 class="h3 mr-3" style="color:#642369;">Flash Sale <small class="font-size-sm"></small></h2> 
          <TimerCoundown2 />
        </div>
        <!-- <div class="pt-3"><a class="btn btn-sm" href="#">More products<i class="czi-arrow-right ml-1 mr-n1"></i></a></div> -->
      </div>


     
          <!-- Product-->
          <div class="container" >
            <!-- <carousel  class="row px-0" :items="4" :nav="false" :autoplay="true"> -->
            <carousel  class="row px-0" :responsive="{0:{items:2,nav:false},800:{items:3,nav:false},1180:{items:4,nav:false}}" :autoplay="true">
              <!-- <div class="row"> -->
          <div  class="col-12 col-md-12 col-lg-12 px-2 mb-4" v-for="itemProduct in products" v-bind:key="itemProduct.id" >
            <div class="card products product-card" style="background:unset" >
             
              <!-- <button class="btn-wishlist btn-sm" type="button" data-toggle="tooltip" data-placement="left" title="Add to wishlist"><i class="czi-heart"></i></button> -->
              <small>CODE: {{ itemProduct.kode }}</small>  
                      <AddToWishlist :itemProduct="itemProduct" />
                 
                  <router-link v-bind:to="'/product/detail/'+itemProduct.id+'/'+itemProduct.slug" class="card-img-top d-block overflow-hidden"  >
                  <div class="p-0 w-100 d-flex align-items-start gbr-produk" style="background:#ccc;">
                    <div class="diskon-product text-center" style="margin-top:18px;">{{ itemProduct.discount_persen }}%</div>
                    <!-- <img v-bind:src="itemProduct.galleries[0].photo" alt="Product" class="img-fluid mx-auto" style="float:none" > -->
                    <img :src="'/small/thumb_' + itemProduct.galleries[0].photo" alt="Product" class="img-fluid mx-auto" style="float:none" >
                  </div>
                  </router-link> 
                   
                  <div class="card-body pt-2 px-0" style="background:unset" ><a class="product-meta d-block font-size-sm" href="#"></a>
                    <h4 class="product-title"><router-link v-bind:to="'/product/detail/'+itemProduct.id+'/'+itemProduct.slug" ><b>{{ itemProduct.name }}</b></router-link><br>
                    <small>
                      <strong>{{ itemProduct.carats }}</strong> <br>
                      {{ itemProduct.dimensions }}
                    </small>
                    <div class="d-flex justify-content-between mt-0">
                      <div class="product-price">
                        <!-- <span class="text-accent font-size-lg">Rp {{ itemProduct.price.toLocaleString('id-ID') }}</span> -->
                        <div v-if="itemProduct.discount_status == 1" class="font-size-lg text-accent pt-2" > 
                            Rp {{ itemProduct.discount_price.toLocaleString('id-ID') }} <br>
                          
                            <span class="font-size-xs p-1 mr-2" style="background-color:red; color:#fff; border-radius:3px;"><strong>{{ itemProduct.discount_persen }}%</strong></span> 
                            <span class="font-size-sm" style="text-decoration: line-through;color:gray;">Rp {{ itemProduct.price.toLocaleString('id-ID') }}</span>
                        </div>
                        <div v-else class="font-size-lg text-accent pt-2">Rp {{ itemProduct.price.toLocaleString('id-ID') }}</div> 
                      
                      </div>
                      <!-- <div class="star-rating"><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star"></i></div> -->
                    </div>
                    </h4>
                    
                  </div>
                  </div>
          </div>
        <!-- </div> -->
        </carousel>
          </div>

          

         
      
      </div>

    </section>
</template>

<script>
import carousel from 'vue-owl-carousel';
import AddToWishlist from '@/components/AddToWishlist.vue'
import TimerCoundown2 from '@/components/TimerCoundown2.vue';


export default {
    name: 'ProductDiscount',
    components: {
    carousel,
    AddToWishlist,
    TimerCoundown2
},
  
    data() {
      return {
        products: [],
        keranjangUser:[]
      }
    
      
    }, 
   
     mounted() {
      this.axios.get("/products-flashsale")
      .then((res) => {
            this.products = res.data.data.data
      })
      .catch(err => console.log(err));
    },
    methods: {
 
      saveKeranjang(idProduct, nameProduct, priceProduct, photoProduct) {
        var productStored = {
          "id": idProduct,
          "name": nameProduct,
          "price": priceProduct,
          "photo": photoProduct
        }
        this.keranjangUser.push(productStored);
        const parsed = JSON.stringify(this.keranjangUser);
        localStorage.setItem('keranjangUser', parsed);
      }
    },
   
};
</script>