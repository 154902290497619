<template>
  <div class="mb-3">
    <Countdown end="October 14, 2022"></Countdown>
  </div>
</template>
<script>
  import Countdown from 'vuejs-countdown'
  
  export default {
    components: { Countdown },
    data(){
      return {
        months : ['January','February','March','April','May','June',
                 'July','August','September','October','November','December'],
        endDate:'2022-09-08',
        endDateConvert:'',
        year:'',
        month:'',
        day:''
      }
     
    },
    methods: {
    
    },
    mounted(){

        this.axios.get("/flashsale_event")
        .then((res) => {
              this.endDate = res.data.data.date_start
        })
        .catch(err => console.log(err));

    }
  }
  </script>