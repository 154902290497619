<template>
<div>
      <img v-if="loading" :src="gifURL" class="mt-3" style="position:absolute;z-index:2; top:16px;right:12px; width:67px; opacity:1" @load="onGifLoad" > 
</div>
</template>
<script>
export default {
  name: 'PreloaderUnwishBig',
   props: ['iswishlist'],
  data(){
    return {
      gifURL: '/img/preloader/heart_dislike_besar_00000.gif',
    }
  },

    methods: {
      onGifLoad() {
        var gifFrames = require('gif-frames');
        var fs = require('fs');
      // const self = this;
      gifFrames(
        {
          url: this.gifURL,
          frames: '0-9',
          outputType: 'PNG',
          cumulative: true,
        },
       function (err, frameData) {
        if (err) {
          throw err;
        }
        frameData.forEach(function (frame) {
          frame.getImage().pipe(fs.createWriteStream(
            'image-' + frame.frameIndex + '.png'
          ));
        });
      }
      );

    },
    },
         mounted() {
    setTimeout(() => (this.loading = false), 6000);
  },
      


    
   
};

</script>

<style scoped>
.wishlist-active {
  color:red;
}
</style>